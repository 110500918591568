import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ fill = "monochrome1000", color = "monochrome100", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 160 161" {...props}>
      <circle
        cx="80"
        cy="80.5"
        r="78.5"
        fill={theme.colors[color]}
        fillOpacity="0.86"
        stroke={theme.colors[fill]}
        strokeWidth="3"
      />
      <path
        d="M87.8679 98.797L79.2225 107.447L70.5817 98.797L61.6263 89.8367L50.2656 101.21L79.2225 130.197L108.179 101.21L96.8186 89.8367L87.8679 98.797Z"
        fill={theme.colors[fill]}
      />
      <path
        d="M70.5817 63.5671L79.2225 54.9171L87.8679 63.5671L96.8186 72.5274L108.179 61.1546L79.2225 32.1667L50.2656 61.1546L61.6263 72.5274L70.5817 63.5671Z"
        fill={theme.colors[fill]}
      />
      <path d="M43.6917 67.2717L30 80.978L43.6917 94.6844L57.3834 80.978L43.6917 67.2717Z" fill={theme.colors[fill]} />
      <path
        d="M114.826 67.2741L101.135 80.9805L114.826 94.6868L128.518 80.9805L114.826 67.2741Z"
        fill={theme.colors[fill]}
      />
      <path
        d="M79.221 67.2722L65.5293 80.9785L79.221 94.6848L92.9127 80.9785L79.221 67.2722Z"
        fill={theme.colors[fill]}
      />
    </Svg>
  );
};

export default Icon;
