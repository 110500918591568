import { ThemedProps, variants } from "./types";

export const getEllipsis = ({ ellipsis }: ThemedProps) => {
  if (ellipsis) {
    return `white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;`;
  }
};

const variantStyles = {
  [variants.decor1]: {
    fontFamily: "Orbitron",
    fontWeight: 800,

    fontSizeLaptop: "160px",
    fontSizeMobileS: "80px",

    lineHeightLaptop: "201px",
    lineHeightMobileS: "100px",

    letterSpacing: "-6px",
  },
  [variants.decor2]: {
    fontFamily: "Orbitron",
    fontWeight: 800,

    fontSizeLaptop: "44px",
    fontSizeMobileS: "36px",

    lineHeightLaptop: "55px",
    lineHeightMobileS: "45px",

    letterSpacing: "-2px",
  },
  [variants.decor2Mobile]: {
    fontFamily: "Orbitron",
    fontWeight: 800,

    fontSizeLaptop: "44px",
    fontSizeMobileS: "36px",

    lineHeightLaptop: "55px",
    lineHeightMobileS: "45px",

    letterSpacing: "inherit",
  },
  [variants.body1]: {
    fontFamily: "Noto Sans",
    fontWeight: 400,

    fontSizeLaptop: "16px",
    fontSizeMobileS: "16px",

    lineHeightLaptop: "24px",
    lineHeightMobileS: "24px",

    letterSpacing: "inherit",
  },
  [variants.body1SemiBold]: {
    fontFamily: "Noto Sans",
    fontWeight: 600,

    fontSizeLaptop: "16px",
    fontSizeMobileS: "16px",

    lineHeightLaptop: "22px",
    lineHeightMobileS: "22px",

    letterSpacing: "0.2px",
  },
  [variants.body2]: {
    fontFamily: "Noto Sans",
    fontWeight: 400,

    fontSizeLaptop: "14px",
    fontSizeMobileS: "14px",

    lineHeightLaptop: "19px",
    lineHeightMobileS: "19px",

    letterSpacing: "inherit",
  },
  [variants.buttonLarge]: {
    fontFamily: "Noto Sans",
    fontWeight: 600,

    fontSizeLaptop: "18px",
    fontSizeMobileS: "18px",

    lineHeightLaptop: "25px",
    lineHeightMobileS: "25px",

    letterSpacing: "0.25px",
  },
  [variants.buttonMedium]: {
    fontFamily: "Noto Sans",
    fontWeight: 600,

    fontSizeLaptop: "16px",
    fontSizeMobileS: "14px",

    lineHeightLaptop: "22px",
    lineHeightMobileS: "19px",

    letterSpacing: "0.25px",
  },
  [variants.buttonSmall]: {
    fontFamily: "Noto Sans",
    fontWeight: 600,

    fontSizeLaptop: "12px",
    fontSizeMobileS: "12px",

    lineHeightLaptop: "16px",
    lineHeightMobileS: "16px",

    letterSpacing: "0.25px",
  },
  [variants.smallText]: {
    fontFamily: "Noto Sans",
    fontWeight: 400,

    fontSizeLaptop: "12px",
    fontSizeMobileS: "12px",

    lineHeightLaptop: "16px",
    lineHeightMobileS: "16px",

    letterSpacing: "0.2px",
  },
  [variants.smallTextBold]: {
    fontFamily: "Noto Sans",
    fontWeight: 700,

    fontSizeLaptop: "12px",
    fontSizeMobileS: "9px",

    lineHeightLaptop: "16px",
    lineHeightMobileS: "12px",

    letterSpacing: "0.3px",
  },
  [variants.extraSmallText]: {
    fontFamily: "Noto Sans",
    fontWeight: 500,

    fontSizeLaptop: "6px",
    fontSizeMobileS: "11px",

    lineHeightLaptop: "8px",
    lineHeightMobileS: "15px",

    letterSpacing: "inherit",
  },
};

export const getFontSettings = ({ theme, variant, $fontWeight }: ThemedProps) => {
  return `
    font-family: ${variant && variantStyles[variant].fontFamily}, sans-serif;
    font-weight: ${$fontWeight ? theme.fontWeight[$fontWeight] : variant && variantStyles[variant].fontWeight};
    
    ${theme.mediaQueries.mobileS} {
      font-size: ${variant && variantStyles[variant].fontSizeMobileS};
      line-height: ${variant && variantStyles[variant].lineHeightMobileS};
    }

    ${theme.mediaQueries.laptop} {
      font-size: ${variant && variantStyles[variant].fontSizeLaptop};
      line-height: ${variant && variantStyles[variant].lineHeightLaptop};
    }
    
    letter-spacing: ${variant && variantStyles[variant].letterSpacing};
  `;
};
