import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "accent500", ...props }) => {
  return (
    <Svg viewBox="0 0 24 24" {...props} color={color}>
      <path d="M22.8602 11.5831C22.6637 11.3142 17.9808 5 11.9999 5C6.01902 5 1.33593 11.3142 1.13961 11.5828C0.953465 11.8379 0.953465 12.1838 1.13961 12.4389C1.33593 12.7078 6.01902 19.022 11.9999 19.022C17.9808 19.022 22.6637 12.7077 22.8602 12.4391C23.0466 12.1841 23.0466 11.8379 22.8602 11.5831ZM11.9999 17.5714C7.59435 17.5714 3.77867 13.3806 2.64915 12.0105C3.77721 10.6392 7.58489 6.45054 11.9999 6.45054C16.4052 6.45054 20.2207 10.6407 21.3507 12.0115C20.2226 13.3827 16.4149 17.5714 11.9999 17.5714Z" />
      <path d="M11.9982 7.66025C9.59869 7.66025 7.64648 9.61245 7.64648 12.0119C7.64648 14.4114 9.59869 16.3636 11.9982 16.3636C14.3976 16.3636 16.3498 14.4114 16.3498 12.0119C16.3498 9.61245 14.3976 7.66025 11.9982 7.66025ZM11.9982 14.913C10.3984 14.913 9.09707 13.6116 9.09707 12.0119C9.09707 10.4122 10.3985 9.11083 11.9982 9.11083C13.5978 9.11083 14.8992 10.4122 14.8992 12.0119C14.8992 13.6116 13.5979 14.913 11.9982 14.913Z" />
    </Svg>
  );
};

export default Icon;
