import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "pieChartJackpotColor", ...props }) => {
  return (
    <Svg viewBox="0 0 85 84" {...props} color={color}>
      <path d="M33.9315 43.3995C33.9397 41.2617 32.3417 39.4587 30.2171 39.2109C28.0941 38.9632 26.1221 40.3512 25.6398 42.4331C25.1574 44.5151 26.3157 46.6298 28.3304 47.3419V68.5995C28.3304 69.3722 28.9571 69.9989 29.7298 69.9989C30.5026 69.9989 31.1293 69.3722 31.1293 68.5995V47.3419C32.8011 46.7512 33.9216 45.173 33.9298 43.3995H33.9315ZM29.7315 44.7989C28.9587 44.7989 28.332 44.1722 28.332 43.3995C28.332 42.6267 28.9587 42 29.7315 42C30.5042 42 31.1309 42.6267 31.1309 43.3995C31.1309 44.1722 30.5042 44.7989 29.7315 44.7989Z" />
      <path d="M43.731 63C43.7376 64.7735 44.8581 66.3518 46.5316 66.9424V68.8521L41.7819 69.802C40.4727 70.0629 39.531 71.2129 39.531 72.5468V76.9994C39.531 77.7722 40.1577 78.3989 40.9305 78.3989C41.7032 78.3989 42.3299 77.7722 42.3299 76.9994V72.5468L47.0795 71.5968C48.3887 71.336 49.3305 70.1859 49.3305 68.8521V66.9424C51.3386 66.2271 52.4903 64.1189 52.008 62.0418C51.5256 59.9648 49.5601 58.5818 47.4421 58.8279C45.3241 59.0723 43.7277 60.8688 43.731 63ZM49.3305 63C49.3305 63.7727 48.7037 64.3994 47.931 64.3994C47.1583 64.3994 46.5316 63.7727 46.5316 63C46.5316 62.2272 47.1583 61.6005 47.931 61.6005C48.7037 61.6005 49.3305 62.2272 49.3305 63Z" />
      <path d="M63.028 0.0262315C56.9036 -0.274003 50.9251 1.94904 46.4856 6.17693C42.0461 10.4048 39.5326 16.2684 39.5326 22.3994V33.6H7.33209C3.46842 33.6049 0.336465 36.7352 0.331543 40.6005V77.0011C0.336465 80.8647 3.46678 83.9967 7.33209 84.0016H52.131C55.9947 83.9967 59.1266 80.8664 59.1315 77.0011V40.6005C59.1266 36.7369 55.9963 33.6049 52.131 33.6H50.7315V22.3994C50.7315 19.2987 52.0178 16.3373 54.2819 14.2193C56.5459 12.1012 59.5876 11.0168 62.6819 11.2251C68.6734 11.7485 73.2376 16.8197 73.131 22.8326V32.2005C73.131 34.5204 75.0111 36.4005 77.331 36.4005H80.1315C82.4514 36.4005 84.3315 34.5204 84.3315 32.2005V22.9983C84.5087 10.879 75.126 0.761231 63.028 0.0262315ZM56.3326 40.5989V76.9994C56.3326 79.3193 54.4525 81.1994 52.1326 81.1994H7.33209C5.01225 81.1994 3.13209 79.3193 3.13209 76.9994V40.6005C3.13209 38.2807 5.01225 36.4005 7.33209 36.4005H52.131C54.4508 36.4005 56.331 38.2807 56.331 40.6005L56.3326 40.5989ZM81.5326 32.1989C81.5326 32.9716 80.9059 33.5983 80.1332 33.5983H77.3326C76.5599 33.5983 75.9332 32.9716 75.9332 32.1989V22.8309C76.0365 15.3431 70.3288 9.05131 62.8656 8.42787C62.5473 8.40818 62.234 8.39834 61.9206 8.39834C58.3687 8.38686 54.9463 9.74037 52.3623 12.1783C49.5405 14.8296 47.9376 18.5276 47.9326 22.3978V33.5983H42.3332V22.3978C42.3332 17.033 44.5333 11.9027 48.4183 8.20311C52.3033 4.5035 57.5369 2.55936 62.8951 2.82186C73.5018 3.48467 81.7115 12.3703 81.5326 22.9966V32.1989Z" />
      <path d="M23.2822 57.2037L18.5326 56.2538V54.3441C20.462 53.6616 21.6186 51.6895 21.2724 49.6748C20.9263 47.6585 19.179 46.1852 17.1331 46.1852C15.0873 46.1852 13.34 47.6585 12.9938 49.6748C12.6477 51.6912 13.8043 53.6632 15.7337 54.3441V56.2538C15.7337 57.5876 16.6754 58.7377 17.983 58.9985L22.7326 59.9484V71.4C22.7326 72.1727 23.3593 72.7995 24.132 72.7995C24.9048 72.7995 25.5315 72.1727 25.5315 71.4V59.9484C25.5315 58.6146 24.5898 57.4645 23.2822 57.2037ZM17.1315 49.0006C17.9042 49.0006 18.5309 49.6273 18.5309 50.4C18.5309 51.1727 17.9042 51.7995 17.1315 51.7995C16.3588 51.7995 15.732 51.1727 15.732 50.4C15.732 49.6273 16.3588 49.0006 17.1315 49.0006Z" />
      <path d="M17.6811 69.8037L12.9315 68.8537V66.944C14.8609 66.2615 16.0175 64.2895 15.6714 62.2748C15.3252 60.2585 13.5779 58.7852 11.5321 58.7852C9.48619 58.7852 7.73893 60.2585 7.39276 62.2748C7.04659 64.2912 8.20323 66.2632 10.1326 66.944V68.8537C10.1326 70.1876 11.0743 71.3376 12.3819 71.5985L17.1315 72.5484V77.0011C17.1315 77.7738 17.7582 78.4005 18.531 78.4005C19.3037 78.4005 19.9304 77.7738 19.9304 77.0011V72.5484C19.9304 71.2146 18.9887 70.0645 17.6811 69.8037ZM11.5304 61.6005C12.3031 61.6005 12.9299 62.2272 12.9299 63C12.9299 63.7727 12.3031 64.3994 11.5304 64.3994C10.7577 64.3994 10.131 63.7727 10.131 63C10.131 62.2272 10.7577 61.6005 11.5304 61.6005Z" />
      <path d="M43.7311 56.2521V54.3424C45.6605 53.6599 46.8171 51.6879 46.4709 49.6732C46.1248 47.6569 44.3775 46.1836 42.3316 46.1836C40.2858 46.1836 38.5385 47.6569 38.1923 49.6732C37.8462 51.6895 39.0028 53.6616 40.9322 54.3424V56.2521L36.1826 57.202C34.8734 57.4629 33.9316 58.613 33.9316 59.9468V71.3984C33.9316 72.1711 34.5584 72.7978 35.3311 72.7978C36.1038 72.7978 36.7305 72.1711 36.7305 71.3984V59.9468L41.4802 58.9969C42.7894 58.736 43.7311 57.5859 43.7311 56.2521ZM42.3316 49.0005C43.1044 49.0005 43.7311 49.6273 43.7311 50.4C43.7311 51.1727 43.1044 51.7995 42.3316 51.7995C41.5589 51.7995 40.9322 51.1727 40.9322 50.4C40.9322 49.6273 41.5589 49.0005 42.3316 49.0005Z" />
    </Svg>
  );
};

export default Icon;
