import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "monochrome1000", ...props }) => {
  return (
    <Svg viewBox="0 0 18 18" {...props} color={color}>
      <path d="M11.5563 8.79312C12.6556 7.9975 13.375 6.7075 13.375 5.25C13.375 2.83813 11.4125 0.875 9 0.875C6.5875 0.875 4.625 2.83813 4.625 5.25C4.625 6.7075 5.34437 7.9975 6.44375 8.79312C3.21375 9.8675 0.875 12.9138 0.875 16.5C0.875 16.8456 1.155 17.125 1.5 17.125H16.5C16.845 17.125 17.125 16.8456 17.125 16.5C17.125 12.9138 14.7863 9.8675 11.5563 8.79312ZM5.875 5.25C5.875 3.52688 7.27688 2.125 9 2.125C10.7231 2.125 12.125 3.52688 12.125 5.25C12.125 6.97312 10.7231 8.375 9 8.375C7.27688 8.375 5.875 6.97312 5.875 5.25ZM2.15313 15.875C2.47 12.3756 5.41937 9.625 9 9.625C12.5806 9.625 15.53 12.3756 15.8469 15.875H2.15313Z" />
    </Svg>
  );
};

export default Icon;
