import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Telegram: React.FC<SvgProps> = props => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#clip0_834_3428)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#59AAE7"
        />
        <path
          d="M12.0001 0C11.4696 0 10.9472 0.034875 10.4349 0.101578C16.3227 0.868594 20.8696 5.90311 20.8696 12C20.8696 18.0969 16.3226 23.1314 10.4348 23.8984C10.9472 23.9651 11.4696 24 12.0001 24C18.6275 24 24.0001 18.6274 24.0001 12C24.0001 5.37262 18.6275 0 12.0001 0Z"
          fill="#3D9AE3"
        />
        <path
          d="M7.7198 14.5846L3.8497 12.6496C3.74362 12.5965 3.74259 12.4456 3.84792 12.391L18.5508 4.78609C18.6588 4.73021 18.7835 4.8241 18.7597 4.94335L16.2282 17.6004C16.2102 17.6907 16.1136 17.7415 16.0289 17.7053L12.5886 16.2309C12.5474 16.2132 12.5002 16.2156 12.461 16.2374L8.01544 18.7072C7.91878 18.7608 7.79995 18.691 7.79995 18.5804V14.7144C7.8 14.6594 7.76897 14.6092 7.7198 14.5846Z"
          fill="#FCFCFC"
        />
        <path
          d="M9.38953 15.8889L9.36548 12.0251C9.36534 12.0004 9.37814 11.9774 9.39923 11.9645L15.6757 8.13023C15.7469 8.08678 15.8213 8.181 15.7626 8.24015L10.986 13.0507C10.9807 13.056 10.9763 13.0621 10.973 13.0688L10.1559 14.7028L9.5227 15.921C9.48871 15.9863 9.39 15.9626 9.38953 15.8889Z"
          fill="#D8D7DA"
        />
      </g>
      <defs>
        <clipPath id="clip0_834_3428">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Telegram;
