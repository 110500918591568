import styled from "styled-components";
import { Flex, Box } from "components/layout";

export const StyledLotteryTimerInfoWrapper = styled(Box)<{ isHideBgImage: boolean }>`
  background-image: ${({ isHideBgImage }) => (isHideBgImage ? "none" : `url("/images/our-mission-bg-640.webp")`)};
  background-position: center;
  background-size: cover;

  ${({ theme }) => theme.mediaQueries.laptop} {
    background-image: ${({ isHideBgImage }) => (isHideBgImage ? "none" : `url("/images/our-mission-bg-2880.webp")`)};
  }
`;

export const StyledLotteryTimerInfo = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.colors.monochrome400};
  border-radius: ${({ theme }) => theme.radii.medium};
  background: ${({ theme }) => theme.colors.monthlyTimerBg};

  ${({ theme }) => theme.mediaQueries.laptop} {
    flex-direction: row;
    justify-content: space-between;
    padding: 48px;
  }
`;

export const StyledLotteryCountDownWrapper = styled(Flex)<{ isShowCounterTopBorder?: boolean }>`
  width: 100%;
  flex-direction: column;
  padding-top: ${({ isShowCounterTopBorder }) => (isShowCounterTopBorder ? "12px" : "0")};
  border-top: ${({ theme, isShowCounterTopBorder }) =>
    isShowCounterTopBorder ? `1px solid ${theme.colors.monochrome400}` : "none"};

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: auto;
  }
`;
