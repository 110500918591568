import { Zero } from "@ethersproject/constants";
import { useChainlinkOracleContract } from "./use-contract";
import { useSWRContract } from "hooks";

export const useOraclePrice = ({ seconds }: { seconds: number }) => {
  const chainlinkOracleContract = useChainlinkOracleContract();

  const { data = Zero, isValidating } = useSWRContract([chainlinkOracleContract, "latestAnswer"], {
    refreshInterval: seconds * 1000,
    revalidateOnFocus: false,
  });

  return { price: data, loading: isValidating };
};
