import { useLocation, useNavigate } from "react-router-dom";
// Context
import { useTranslation } from "context";
// Hooks
import { useWeb3Login } from "hooks";
// Components
import { Column } from "components/layout";
import { ExitIcon, ProfileIcon, TableIcon } from "components/svg";
// Configs
import { ROUTES } from "navigation/routes";
// Styles
import { Divider, ProfileButton } from "./styled";

const ProfilePopup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logout } = useWeb3Login();
  const { pathname } = useLocation();

  const handleProfileClick = () => {
    navigate(`/${ROUTES.profile}`);
  };

  const handleTableClick = () => {
    navigate(`/${ROUTES.main}`);
  };

  const isOnProfilePage = pathname === `/${ROUTES.profile}`;
  const isOnMainPage = pathname === `/${ROUTES.main}`;

  return (
    <Column pb="13px" width="100%">
      {!isOnMainPage && (
        <>
          <ProfileButton startIcon={<TableIcon />} onClick={handleTableClick} variant="primary" scale="sm" hsl="50">
            {t("Tables")}
          </ProfileButton>
          <Divider />
        </>
      )}

      {!isOnProfilePage && (
        <>
          <ProfileButton startIcon={<ProfileIcon />} onClick={handleProfileClick} variant="primary" scale="sm" hsl="50">
            {t("My profile")}
          </ProfileButton>
          <Divider />
        </>
      )}

      <ProfileButton startIcon={<ExitIcon />} onClick={logout} variant="primary" scale="sm" hsl="50">
        {t("Logout")}
      </ProfileButton>
    </Column>
  );
};

export default ProfilePopup;
