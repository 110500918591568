import React from "react";
// Types
import { ProgressLineProps } from "./types";
// Styles
import { StyledProgressLine, StyledCurrentProgress } from "./styled";

const ProgressLine: React.FC<ProgressLineProps> = ({ progress = 0 }) => {
  return (
    <StyledProgressLine>
      <StyledCurrentProgress progress={progress} />
    </StyledProgressLine>
  );
};

export default ProgressLine;
