import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
// Store
import { useAppDispatch } from "store/store";
import { setActiveTab } from "store/profile/reducer";
// Context
import { useTranslation } from "context";
// Components
import Text from "components/text";
import InternalLink from "components/link";
// Configs
import { ROUTES } from "navigation/routes";
// Types
import { UserProfileTabs } from "pages/profile/types";

interface ToastDescriptionWithTxProps {
  description: string;
  profilePage?: UserProfileTabs;
}

const ToastDescriptionLotteryDraw: React.FC<PropsWithChildren<ToastDescriptionWithTxProps>> = ({
  description,
  profilePage = UserProfileTabs.GameStatistics,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLinkClick = () => {
    dispatch(setActiveTab(profilePage));
    navigate(`/${ROUTES.profile}`);
  };

  return (
    <>
      <Text as="p">{description}</Text>
      <InternalLink onClick={handleLinkClick}>{t("View statistics")}</InternalLink>
    </>
  );
};

export default ToastDescriptionLotteryDraw;
