import { useMemo } from "react";
import useSWR from "swr";
import { Zero } from "@ethersproject/constants";
import { parseUnits, formatUnits } from "@ethersproject/units";
import { BigNumber } from "@ethersproject/bignumber";

import { useActiveWeb3React, useSingleContractMultipleData, useSlotsContract, useGetLastDrawBlock } from "hooks";
import { NEVER_RELOAD } from "hooks/use-multicall/constants";

import { formatBigNumber, ZERO_ADDRESS } from "utils/web3";
import { Monthly, Weekly } from "configs/abi/types";
import { LotteryWinners } from "./types";

const defaultData = {
  winners: [],
  txHash: "",
  prizes: [],
};
export const useGetLotteryWinners = (
  contract: Weekly | Monthly | null,
  nominal: string | null,
  lastDrawLotteryBlock?: BigNumber,
) => {
  const { chainId } = useActiveWeb3React();

  const slotsContract = useSlotsContract(chainId);
  const { lastDrawBlock, getNewBlock } = useGetLastDrawBlock(contract, nominal);
  const lastLotteryBlock = lastDrawLotteryBlock ?? lastDrawBlock;

  const { data = defaultData, isValidating } = useSWR(
    lastLotteryBlock && chainId && contract ? `${chainId}/getAllLotteryWinners/${lastLotteryBlock}/${contract}` : null,
    async () => {
      return getLotteryWinners();
    },
    {
      revalidateOnFocus: false,
    },
  );

  const getLotteryWinners = async () => {
    const from = +formatBigNumber(lastLotteryBlock, 0, 0);
    const eventFilter = contract!.filters.LotteryDrawn();
    const events = await contract!.queryFilter(eventFilter, from, from);

    let currEvent;
    if (nominal) {
      currEvent = events.find(item => {
        const nominalBN = (item?.args?.["bet"] as BigNumber) || Zero;
        const nominalBNB = formatBigNumber(nominalBN);
        const nominalWei = formatUnits(parseUnits(nominalBNB), "wei");

        return item.blockNumber === from && nominalWei === nominal;
      });
    } else {
      currEvent = events.find(item => item.blockNumber === from);
    }
    const txHash = currEvent?.transactionHash ?? "";
    const lastLottery = currEvent?.args;
    const winners = lastLottery?.["winners"] ?? [];
    const prizes = lastLottery?.["prizes"] ?? [];

    return { winners, txHash, prizes };
  };

  const allWinnersNicknames = useSingleContractMultipleData(
    slotsContract,
    "userProfiles",
    data?.winners.map(winnerAddress => [winnerAddress]),
    NEVER_RELOAD,
  );

  const anyLoading = useMemo(() => allWinnersNicknames.some(callState => callState.loading), [allWinnersNicknames]);

  const lastLotteryWinners: LotteryWinners[] = useMemo(() => {
    if (allWinnersNicknames.length && !anyLoading) {
      const prizes = data.prizes;
      return prizes
        .map((winnerPrize, i) => ({
          position: i + 1,
          winnerPrize,
          wallet: data.winners[i],
          txHash: data.txHash,
          nickname: allWinnersNicknames[i].result?.["nickname"] ?? "",
        }))
        .filter(winner => winner.wallet !== ZERO_ADDRESS);
    }
    return [];
  }, [allWinnersNicknames, data.winners]);

  return {
    lastLotteryWinners,
    isLoading: isValidating || anyLoading,
    getNewBlock,
    lastDrawBlock,
  };
};
