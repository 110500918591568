import { DefaultTheme } from "styled-components";
import { LayoutProps, SpaceProps, TypographyProps, OpacityProps } from "styled-system";

import { Colors, FontWeight } from "theme/types";

export interface ThemedProps extends TextProps {
  theme: DefaultTheme;
}

export const variants = {
  decor1: "decor1",
  decor2: "decor2",
  decor2Mobile: "decor2Mobile",
  body1: "body1",
  body1SemiBold: "body1SemiBold",
  body2: "body2",
  buttonLarge: "buttonLarge",
  buttonMedium: "buttonMedium",
  buttonSmall: "buttonSmall",
  smallText: "smallText",
  smallTextBold: "smallTextBold",
  extraSmallText: "extraSmallText",
} as const;

export type Variants = typeof variants[keyof typeof variants];

export interface TextProps extends SpaceProps, TypographyProps, LayoutProps, OpacityProps {
  variant?: Variants;
  color?: keyof Colors;
  ellipsis?: boolean;
  $fontWeight?: keyof FontWeight;
  textTransform?: "uppercase" | "lowercase" | "capitalize";
}
