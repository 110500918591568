import { createGlobalStyle } from "styled-components";

/* Orbitron Font */
import OrbitronRegularWoff from "assets/fonts/orbitron-v25-latin-regular.woff";
import OrbitronRegularWoff2 from "assets/fonts/orbitron-v25-latin-regular.woff2";

import Orbitron500Woff from "assets/fonts/orbitron-v25-latin-500.woff";
import Orbitron500Woff2 from "assets/fonts/orbitron-v25-latin-500.woff2";

import Orbitron600Woff from "assets/fonts/orbitron-v25-latin-600.woff";
import Orbitron600Woff2 from "assets/fonts/orbitron-v25-latin-600.woff2";

import Orbitron700Woff from "assets/fonts/orbitron-v25-latin-700.woff";
import Orbitron700Woff2 from "assets/fonts/orbitron-v25-latin-700.woff2";

import Orbitron800Woff from "assets/fonts/orbitron-v25-latin-800.woff";
import Orbitron800Woff2 from "assets/fonts/orbitron-v25-latin-800.woff2";

/* Noto Sans Font */
import NotoSansRegularWoff from "assets/fonts/noto-sans-v27-latin-regular.woff";
import NotoSansRegularWoff2 from "assets/fonts/noto-sans-v27-latin-regular.woff2";

import NotoSans500Woff from "assets/fonts/noto-sans-v27-latin-500.woff";
import NotoSans500Woff2 from "assets/fonts/noto-sans-v27-latin-500.woff2";

import NotoSans600Woff from "assets/fonts/noto-sans-v27-latin-600.woff";
import NotoSans600Woff2 from "assets/fonts/noto-sans-v27-latin-600.woff2";

import NotoSans700Woff from "assets/fonts/noto-sans-v27-latin-700.woff";
import NotoSans700Woff2 from "assets/fonts/noto-sans-v27-latin-700.woff2";

const GlobalStyle = createGlobalStyle`

/* Orbitron Font */
@font-face {
  font-family: "Orbitron";
  src: local("Orbitron Regular"),
  url(${OrbitronRegularWoff}) format("woff"),
  url(${OrbitronRegularWoff2}) format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Orbitron";
  src: local("Orbitron 500"),
  url(${Orbitron500Woff}) format("woff"),
  url(${Orbitron500Woff2}) format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Orbitron";
  src: local("Orbitron 600"),
  url(${Orbitron600Woff}) format("woff"),
  url(${Orbitron600Woff2}) format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Orbitron";
  src: local("Orbitron 700"),
  url(${Orbitron700Woff}) format("woff"),
  url(${Orbitron700Woff2}) format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Orbitron";
  src: local("Orbitron 800"),
  url(${Orbitron800Woff}) format("woff"),
  url(${Orbitron800Woff2}) format("woff2");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

/* Noto Sans Font */
@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans Regular"),
  url(${NotoSansRegularWoff}) format("woff"),
  url(${NotoSansRegularWoff2}) format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans 500"),
  url(${NotoSans500Woff}) format("woff"),
  url(${NotoSans500Woff2}) format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans 600"),
  url(${NotoSans600Woff}) format("woff"),
  url(${NotoSans600Woff2}) format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans 700"),
  url(${NotoSans700Woff}) format("woff"),
  url(${NotoSans700Woff2}) format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* General */

html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1.5;
    font-size: 16px;
  }
  ol,
  ul {
    list-style: disc;
    list-style-position: inside;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  [role="button"] {
    cursor: pointer;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* Number */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  html {
    scroll-behavior: smooth;
  }

  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
    background-color: ${({ theme }) => theme.colors.monochrome0};

  }
  ::-webkit-scrollbar-thumb {
    background-image: linear-gradient(180deg, #D0368A 0%, #708AD4 99%);
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
    border-radius: 100px;
  }


  /* Slider */ 
  input[type=range] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
  }
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  input[type=range]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }
  input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
    /* Hides the slider so custom styles can be added */
    background: transparent; 
    border-color: transparent;
    color: transparent;
  }
  body {
    line-height: 1.5;
    font-size: 16px;
    overflow-x: hidden;
    min-width: 100vw;
    min-height: 100vh;
    margin: 0;
    font-family: "Noto Sans, sans-serif";
    background-color: ${({ theme }) => theme.colors.monochrome0};
    
    img {
      height: auto;
      max-width: 100%;
    }
  }
  #root {
    height: 100%;
    min-height: 100vh;
  }
`;

export default GlobalStyle;
