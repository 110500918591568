import { Web3Provider } from "@ethersproject/providers";

import { isGasEstimationError, isUserRejected, TxError } from "utils/web3";

// account is not optional
export const getSigner = (library: Web3Provider, account: string) => {
  return library.getSigner(account).connectUnchecked();
};

// account is optional
export const getProviderOrSigner = (library: Web3Provider, account?: string | null | undefined) => {
  return account ? getSigner(library, account) : library;
};

export const isExceededBalance = ({
  nativeBalance,
  gasEstimation,
}: {
  nativeBalance: number;
  gasEstimation: number;
}) => {
  return nativeBalance < gasEstimation;
};

export const getErrorMessage = (error: Error | TxError | string, errorString?: string) => {
  let message = errorString ?? "Sorry, can't perform a transaction";

  if (isUserRejected(error as Error & { code: number })) {
    message = "User rejected the request";
  } else if ((error as TxError)?.data) {
    if (isGasEstimationError(error)) {
      message = "Insufficient funds";
    }
  } else if ((error as Error)?.message) {
    if (isGasEstimationError(error)) {
      message = "Insufficient funds";
    }
  }

  return message;
};
