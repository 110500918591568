import React, { useEffect, useState } from "react";
// Context
import { useTranslation } from "context";
// Components
import { Heading, Text } from "components";
// Constants
import { TabPanelProps } from "./types";
// Styles
import { StyledTabPanelWrapper, StyledTabPanel, StyledTabPanelButton } from "./styled";

// buttons arr mast have already translated text
const TabPanel: React.FC<TabPanelProps> = ({
  buttons,
  defaultActiveButton = 0,
  headingScale,
  textVariant,
  isText = false,
  isShowLineOnHover = false,
  isBorderAroundTab = false,
  isShowTopDivider = false,
  callback,
  innerTabComponentProps = {},
  ...props
}) => {
  // TODO For future (not for beta) Add swiper with arrows an blur when tabs list is scrollable
  const { t } = useTranslation();

  const [activeButton, setActiveButton] = useState(defaultActiveButton);

  useEffect(() => {
    setActiveButton(defaultActiveButton);
  }, [defaultActiveButton]);

  const handleClick = (index: number) => {
    if (callback) {
      callback(index);
    } else {
      setActiveButton(index);
    }
  };

  const SelectedComponentWrapper = isText ? Text : Heading;
  const componentWrapperProps = isText ? { variant: textVariant } : { scale: headingScale };

  return (
    <StyledTabPanelWrapper isShowTopDivider={isShowTopDivider}>
      <StyledTabPanel buttonsCount={buttons.length} activeButton={activeButton} {...props}>
        {buttons.map((button, index) => (
          <StyledTabPanelButton
            key={index}
            isActive={activeButton === index}
            isShowLineOnHover={isShowLineOnHover}
            isBorderAroundTab={isBorderAroundTab}
            onClick={() => handleClick(index)}
          >
            <SelectedComponentWrapper
              color={activeButton === index || isBorderAroundTab ? "monochrome1000" : "monochrome600"}
              p={{ _: "12px 4px", laptop: "16px 24px", laptopL: "16px 40px" }}
              {...componentWrapperProps}
              {...innerTabComponentProps}
            >
              {t(button)}
            </SelectedComponentWrapper>
          </StyledTabPanelButton>
        ))}
      </StyledTabPanel>
    </StyledTabPanelWrapper>
  );
};

export default TabPanel;
