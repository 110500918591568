import React from "react";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints, useScrollSpy } from "hooks";
// Components
import { Link, Flex, Box, Text } from "components";
// Constants
import { NAV_LIST } from "./constants";

const HEADER_OFFSET = 120; //px

const NavList: React.FC<{ setVisible?: (visible: boolean) => void }> = ({ setVisible }) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const ids = NAV_LIST.map(({ link }) => link);

  const activeId = useScrollSpy(ids, HEADER_OFFSET);

  const handleCloseBurgerMenu = () => {
    if (setVisible) {
      setVisible(false);
    }
  };

  return (
    <Flex flexDirection={isDesktop ? "row" : "column"} alignItems={isDesktop ? "start" : "center"}>
      {NAV_LIST.map(({ label, link }) => {
        const isActive = activeId === link;
        return (
          <Link href={`#${link}`} key={link}>
            <Box px="16px" my={isDesktop ? "0" : "12px"} onClick={handleCloseBurgerMenu}>
              <Text
                variant="buttonSmall"
                onClick={e => {
                  e.stopPropagation();
                  handleCloseBurgerMenu();
                }}
                color={isActive ? "monochrome1000" : "monochrome700"}
              >
                {t(label)}
              </Text>
            </Box>
          </Link>
        );
      })}
    </Flex>
  );
};

export default NavList;
