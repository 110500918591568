import React from "react";
import { useThemeContext } from "context";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "pieChartJackpotColor", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 40 40" {...props} color="transparent" stroke={theme.colors[color]}>
      <g clipPath="url(#clip0_1500_10062)">
        <path
          d="M0.78125 39.2188H12.5V26.0938H0.78125V39.2188Z"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5469 20.1562H29.4531"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.0001 14.0686L16.1619 16.87C15.5194 17.339 14.6527 16.7094 14.9002 15.9533L16.3784 11.4373L12.5205 8.64716C11.8765 8.18154 12.206 7.16388 13.0006 7.16388H17.7618L19.2205 2.65138C19.465 1.89498 20.5351 1.89498 20.7797 2.65138L22.2383 7.16388H26.9995C27.7941 7.16388 28.1236 8.18154 27.4797 8.64716L23.6217 11.4373L25.0999 15.9533C25.3474 16.7094 24.4808 17.339 23.8382 16.87L20.0001 14.0686Z"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.2656 26.25H20.7351V33.125"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.5 36.1719V39.2188H12.5V20.1563H27.5V29.1407"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.1953 3.3869L27.8009 0.781277"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.7267 3.3869L12.1211 0.781277"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.0957 12.9863L30.9723 14.3645"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.904 12.9863L9.02734 14.3645"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.5 26.0938H39.2188V39.2188H27.5"
          strokeWidth="1.6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1500_10062">
          <rect width="40" height="40" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
