import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "monochrome1000", ...props }) => {
  return (
    <Svg viewBox="0 0 20 20" {...props} color={color}>
      <path d="M12.3529 2.35315H7.64706C3.43047 2.35315 0 5.78362 0 10.0002C0 14.2168 3.43047 17.6473 7.64706 17.6473H12.3529C16.5695 17.6473 20 14.2168 20 10.0002C20 5.78362 16.5695 2.35315 12.3529 2.35315ZM12.3529 16.4708H7.64706C4.07918 16.4708 1.17647 13.5681 1.17647 10.0002C1.17647 6.43233 4.07918 3.52962 7.64706 3.52962H12.3529C15.9208 3.52962 18.8235 6.43233 18.8235 10.0002C18.8235 13.5681 15.9208 16.4708 12.3529 16.4708Z" />
      <path d="M7.05884 10.5885H12.9412V7.05908H7.05884V10.5885ZM8.23531 8.23555H11.7647V9.41202H8.23531V8.23555Z" />
      <path d="M7.05884 11.7649H8.23531V12.9414H7.05884V11.7649Z" />
      <path d="M9.41187 11.7649H10.5883V12.9414H9.41187V11.7649Z" />
      <path d="M11.7646 11.7649H12.9411V12.9414H11.7646V11.7649Z" />
      <path d="M14.1177 9.41199H15.2941V10.5885H14.1177V9.41199Z" />
      <path d="M4.70581 9.41199H5.88228V10.5885H4.70581V9.41199Z" />
    </Svg>
  );
};

export default Icon;
