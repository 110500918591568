import useSWR from "swr";
import { Zero } from "@ethersproject/constants";
import { BigNumber } from "@ethersproject/bignumber";
// Store
import { useAppDispatch } from "store/store";
import { setLastWeeklyLotteryBlockForNominal, setNominalLastLotteryBank } from "store/nominals";

import { useActiveWeb3React, useGetLastDrawBlock, showedNominals, useWeeklyLotteryContractFromCustomRPC } from "hooks";

import { formatBigNumber, formatBigNumberToFixed } from "utils/web3";
import { useEffect } from "react";

// In lottery players wins amount takes 70%;
const PLAYERS_WIN_PERCENTAGE = 0.7;
// Firs place winning price takes 20% from 70% of all bank
const FIRS_PLACE_PERCENTAGE_PRIZE = 0.2;

export const useGetLastWeekBanks = (nominal: string | null = showedNominals[0].nominalWEI) => {
  const { chainId } = useActiveWeb3React();

  const dispatch = useAppDispatch();

  const weeklyContractFromCustomRPC = useWeeklyLotteryContractFromCustomRPC(chainId);

  const { lastDrawBlock } = useGetLastDrawBlock(weeklyContractFromCustomRPC, nominal);

  useEffect(() => {
    dispatch(setLastWeeklyLotteryBlockForNominal({ lastDrawBlock }));
  }, [lastDrawBlock]);

  useSWR(
    lastDrawBlock && chainId && nominal !== undefined && weeklyContractFromCustomRPC
      ? `${chainId}/getAllLotteryWinners/${lastDrawBlock}/${weeklyContractFromCustomRPC}/${nominal}`
      : null,
    async () => {
      return getLastWeekBanks();
    },
    {
      revalidateOnFocus: false,
    },
  );

  const getLastWeekBanks = async () => {
    const from = +formatBigNumber(lastDrawBlock, 0, 0);
    const eventFilter = weeklyContractFromCustomRPC!.filters.LotteryDrawn();
    const events = await weeklyContractFromCustomRPC!.queryFilter(eventFilter, from, from);

    events.forEach(item => {
      const eventResult = item?.args;
      const nominalBN: BigNumber = (eventResult?.["bet"] as BigNumber) || Zero;
      const nominalBNB = +formatBigNumber(nominalBN);
      const prizes = eventResult?.["prizes"] ?? [];
      const winnerPrize = prizes.length ? prizes[0] : Zero;

      dispatch(
        setNominalLastLotteryBank({
          nominal: nominalBNB.toString(),
          // to find lastLotteryBank of previous lottery at first we count 70% of all bank (winnerPrize divide to FIRS_PLACE_PERCENTAGE_PRIZE(20%)) and then we count 100% of all bank (prev result divide to PLAYERS_WIN_PERCENTAGE(70%))
          lastLotteryBank: +formatBigNumberToFixed(winnerPrize) / FIRS_PLACE_PERCENTAGE_PRIZE / PLAYERS_WIN_PERCENTAGE,
        }),
      );
    });
  };
};
