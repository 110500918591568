import styled from "styled-components";
import { Flex } from "components";
import { TableFullnessProps } from "./types";
import { getTableFullnessStyles } from "./theme";

export const StyledTableFullness = styled(Flex)<TableFullnessProps>`
  justify-content: flex-end;
  align-items: center;

  ${({ theme, currentFullness, maxFullness }) => getTableFullnessStyles({ theme, currentFullness, maxFullness })};

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 70px;
    height: 32px;
    justify-content: center;
    margin: 0 16px;
    padding: 8px;
    border-radius: ${({ theme }) => theme.radii.small};
    box-shadow: ${({ theme }) => theme.shadows.card};
  }
`;
