import { useThemeContext } from "context";
import { StyledBlockies } from "./styled";
import { AvatarBlockiesProps, variants } from "./types";

export const AvatarBlockies: React.FC<AvatarBlockiesProps> = ({ seed, size, scale, bgColor, spotColor, ...props }) => {
  const { theme } = useThemeContext();

  return (
    <StyledBlockies
      {...props}
      seed={seed}
      size={size}
      scale={scale}
      bgColor={bgColor ?? theme.colors.accent400}
      spotColor={spotColor ?? theme.colors.link400}
    />
  );
};

AvatarBlockies.defaultProps = {
  size: 5,
  scale: 30,
  variant: variants.CIRCLE,
};
