import { DefaultTheme } from "styled-components";

export const variants = {
  error: "error",
  warning: "warning",
  success: "success",
} as const;

export interface ThemedProps extends ProgressLineProps {
  theme: DefaultTheme;
}

export interface ProgressLineProps {
  progress: number;
}
