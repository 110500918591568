import { css } from "styled-components";
import { getCurrentProgressVariant } from "components/progress-line/theme";
import { ThemedProps, variants } from "./types";

const getTableFullnessVariant = (currentFullness: number, maxFullness: number) => {
  if (maxFullness === 0) {
    return variants.error;
  } else {
    const percent = +((currentFullness / maxFullness) * 100).toFixed();

    return getCurrentProgressVariant(percent);
  }
};

export const getTableFullnessStyles = ({ theme, currentFullness, maxFullness }: ThemedProps) => {
  let variant = getTableFullnessVariant(currentFullness, maxFullness);

  return {
    error: css`
      ${theme.mediaQueries.laptop} {
        background: ${theme.colors.errorTableFullnessBg};
      }
      p {
        color: ${theme.colors.error600};
      }
    `,
    warning: css`
      ${theme.mediaQueries.laptop} {
        background: ${theme.colors.warningTableFullnessBg};
      }
      p {
        color: ${theme.colors.warning500};
      }
    `,
    success: css`
      ${theme.mediaQueries.laptop} {
        background: ${theme.colors.successTableFullnessBg};
      }
      p {
        color: ${theme.colors.success600};
      }
    `,
  }[variant];
};
