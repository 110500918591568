import React from "react";
// Components
import { Flex, Text, Heading } from "components";
import { StyledModalWrapper } from "../styled";
import { StyledCard } from "./styled";
import { CloseIcon } from "components/svg";
// Store
import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";
// Hooks
import { useTranslation } from "context";
import { useProviders } from "hooks";
// Constants
import { ChooseWalletModalProps } from "./types";

const ChooseWalletModal: React.FC = () => {
  const { onClick } = useAppSelector(state => state.modal.props as unknown as ChooseWalletModalProps);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { providers } = useProviders();

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const onCardClick: ChooseWalletModalProps["onClick"] = provider => {
    onClick(provider);
    closeModalHandler();
  };

  return (
    <StyledModalWrapper width="300px">
      <Flex width="100%" justifyContent="flex-end">
        <CloseIcon width="24px" height="24px" cursor="pointer" onClick={closeModalHandler} />
      </Flex>
      <Heading as="h5" scale="h5" textAlign="start" width="100%" color="monochrome900" my="10px">
        {t("Choose a wallet")}
      </Heading>

      {providers.map(provider => {
        const { icon: Icon, title } = provider;
        return (
          <StyledCard key={title} onClick={() => onCardClick(provider)}>
            <Icon width="40px" height="40px" />
            <Text mx="12px" color="monochrome700" variant="body1">
              {title}
            </Text>
          </StyledCard>
        );
      })}
    </StyledModalWrapper>
  );
};

export default ChooseWalletModal;
