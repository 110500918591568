import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Zero } from "@ethersproject/constants";
import { showedNominals } from "hooks/use-nominals-info/constants";
import { NominalsStateType, TimestampState, WeekBanks } from "./types";
import { BigNumber } from "@ethersproject/bignumber";

function createCounter(): { [key: string]: boolean } {
  const counter: { [key: string]: boolean } = {};
  showedNominals.forEach(item => {
    counter[item.nominalBNB] = false;
  });
  return counter;
}

function createWeekBanks(): { [key: string]: number } {
  const counter: { [key: string]: number } = {};
  showedNominals.forEach(item => {
    counter[item.nominalBNB] = 0;
  });
  return counter;
}

const initialState: NominalsStateType = {
  timestamps: {
    monthly: false,
    ...createCounter(),
  },
  weekBanks: {
    monthly: 0,
    ...createWeekBanks(),
  },
  lastWeeklyLotteryDrawBlockForNominal: Zero,
};

export const nominalsSlice = createSlice({
  name: "nominalsState",
  initialState,
  reducers: {
    checkedNominalTimestamp: (
      state: NominalsStateType,
      action: PayloadAction<{ nominal: keyof TimestampState; isChecked: boolean }>,
    ) => {
      state.timestamps[action.payload.nominal] = action.payload.isChecked;
    },
    setNominalLastLotteryBank: (
      state: NominalsStateType,
      action: PayloadAction<{ nominal: keyof WeekBanks; lastLotteryBank: number }>,
    ) => {
      state.weekBanks[action.payload.nominal] = action.payload.lastLotteryBank;
    },
    setLastWeeklyLotteryBlockForNominal: (
      state: NominalsStateType,
      action: PayloadAction<{ lastDrawBlock: BigNumber }>,
    ) => {
      state.lastWeeklyLotteryDrawBlockForNominal = action.payload.lastDrawBlock;
    },
    resetNominals: () => initialState,
  },
});

export const {
  checkedNominalTimestamp,
  resetNominals,
  setNominalLastLotteryBank,
  setLastWeeklyLotteryBlockForNominal,
} = nominalsSlice.actions;
export default nominalsSlice;
