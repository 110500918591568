import { HeadingProps, scales } from "./types";

const style = {
  [scales.h1]: {
    fontWeight: 800,

    fontSizeLaptop: "48px",
    fontSizeMobileS: "32px",

    lineHeightLaptop: "60px",
    lineHeightMobileS: "40px",

    letterSpacing: "inherit",
  },
  [scales.h2]: {
    fontWeight: 700,

    fontSizeLaptop: "36px",
    fontSizeMobileS: "36px",

    lineHeightLaptop: "45px",
    lineHeightMobileS: "43px",

    letterSpacing: "inherit",
  },
  [scales.h3]: {
    fontWeight: 600,

    fontSizeLaptop: "28px",
    fontSizeMobileS: "28px",

    lineHeightLaptop: "35px",
    lineHeightMobileS: "35px",

    letterSpacing: "inherit",
  },
  [scales.h4]: {
    fontWeight: 400,

    fontSizeLaptop: "28px",
    fontSizeMobileS: "26px",

    lineHeightLaptop: "35px",
    lineHeightMobileS: "33px",

    letterSpacing: "inherit",
  },
  [scales.h5]: {
    fontWeight: 700,

    fontSizeLaptop: "24px",
    fontSizeMobileS: "24px",

    lineHeightLaptop: "32px",
    lineHeightMobileS: "32px",

    letterSpacing: "0.15px",
  },
  [scales.h6]: {
    fontWeight: 500,

    fontSizeLaptop: "18px",
    fontSizeMobileS: "18px",

    lineHeightLaptop: "24px",
    lineHeightMobileS: "24px",

    letterSpacing: "0.1px",
  },
};
export const getHeadingSettings = ({ theme, scale }: HeadingProps) => {
  return `
    font-family: ${theme.fonts.Orbitron};
    font-weight: ${scale && style[scale].fontWeight};

    ${theme.mediaQueries.mobileS} {
      font-size: ${scale && style[scale].fontSizeMobileS};
      line-height: ${scale && style[scale].lineHeightMobileS};
    }

    ${theme.mediaQueries.laptop} {
      font-size: ${scale && style[scale].fontSizeLaptop};
      line-height: ${scale && style[scale].lineHeightLaptop};
    }

    letter-spacing: ${scale && style[scale].letterSpacing};
  `;
};
