import { ElementType, PropsWithChildren, ReactNode } from "react";
import { LayoutProps, SpaceProps } from "styled-system";
// Types
import { PolymorphicComponentProps } from "types";
import { SvgProps } from "components/svg/types";
import { Colors, HSL } from "theme/types";
import { variants as textVariants } from "components/text/types";

export const variants = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
} as const;

export const scales = {
  SM: "sm",
  MD: "md",
  LG: "lg",
} as const;

export const accentColor = {
  accent: "accent",
  success: "success",
  warning: "warning",
  error: "error",
  grey: "grey",
} as const;

export const scalesToTextVariant = {
  [scales.SM]: textVariants.buttonSmall,
  [scales.MD]: textVariants.buttonMedium,
  [scales.LG]: textVariants.buttonLarge,
};

export type Scale = typeof scales[keyof typeof scales];
export type AccentColor = typeof accentColor[keyof typeof accentColor];
export type Variant = typeof variants[keyof typeof variants];

export interface BaseButtonProps
  extends LayoutProps,
    SpaceProps,
    PropsWithChildren<{
      as?: "a" | "button" | ElementType;
      external?: boolean;
      isLoading?: boolean;
      scale?: Scale;
      variant?: Variant;
      accentColor?: AccentColor;
      disabled?: boolean;
      startIcon?: ReactNode & SvgProps;
      endIcon?: ReactNode & SvgProps;
      hsl?: keyof HSL;
      color?: keyof Colors;
    }> {}

export type ButtonProps<P extends ElementType = "button"> = PolymorphicComponentProps<P, BaseButtonProps>;
