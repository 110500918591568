import React from "react";

import { useMatchBreakpoints } from "hooks";

import { Text, Heading, Flex, Button, AvatarBlockies } from "components";
import { CloseIcon } from "components/svg";
import { StyledModalWrapper } from "../styled";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";

import { RegistrationModalProps } from "./types";
import { useTranslation } from "context";

const RegistrationModal: React.FC = () => {
  const { clickHandler, account } = useAppSelector(state => state.modal.props as unknown as RegistrationModalProps);

  const { isDesktop } = useMatchBreakpoints();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const onSubmitHandler = () => {
    closeModalHandler();
    clickHandler();
  };

  return (
    <StyledModalWrapper>
      <Flex justifyContent="end" width="100%">
        <CloseIcon onClick={closeModalHandler} />
      </Flex>

      <AvatarBlockies seed={account ?? ""} scale={8} size={8} my="16px" />

      <Heading textAlign="center" scale={isDesktop ? "h2" : "h4"} as="h4">
        {t("Get your game passport NFT")}
      </Heading>

      <Text variant="body1" textAlign="center" color="monochrome800" mt="8px" width="70%">
        {t("NFT profile generation is free of charge, you need to cover only the network fee.")}
      </Text>

      <Button mt="16px" scale={isDesktop ? "md" : "sm"} variant="primary" onClick={onSubmitHandler}>
        {t("Create")}
      </Button>
    </StyledModalWrapper>
  );
};

export default RegistrationModal;
