import styled from "styled-components";
import Text from "components/text";
import { LinkProps } from "./types";

export const StyledLink = styled(Text)<LinkProps>`
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  &:hover {
    text-decoration: ${({ underline }) => underline && "underline"};
    text-underline-offset: ${({ theme, isActive }) => (isActive ? theme.radii.medium : theme.radii.small)};
  }
`;
