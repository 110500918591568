export type Currency = {
  name: string;
  symbol: string;
  decimals: number;
};

// TODO: In case when customer wants to extends platform to another network
// Please replace all `BNB` with `nativeCurrencies[chainId].symbol`
// Recheck oracle price, block number, blocksPerFetch and others
const chainIdMainnet = {
  bsc: 56,
  mainnet: 1,
};

const chainIdTestnet = {
  bscTest: 97,
};

const getChainIds = () => {
  return [+(process.env.REACT_APP_CHAIN_ID as string)];
};

// Check actual rpcUrls on https://chainlist.org/
const rpcUrls = {
  // Main
  [chainIdMainnet.bsc]: "https://bsc-dataseed.binance.org",

  // Added here to work with oracle price
  [chainIdMainnet.mainnet]: "https://rpc.ankr.com/eth",
  // Test
  [chainIdTestnet.bscTest]: "https://data-seed-prebsc-1-s3.binance.org:8545",
};
// Attention!
// `The eth_getLogs api has been turned off in the public RPCs` for bsc mainnet
// https://github.com/bnb-chain/bsc/issues/1215
const rpcUrlsForEventsParsing = {
  // Custom proxy url that cache requests
  [chainIdMainnet.bsc]: "https://node-rpc.idealogic.dev/",

  [chainIdTestnet.bscTest]: "https://data-seed-prebsc-1-s3.binance.org:8545",
};

const blockExplorersUrls = {
  // Main
  [chainIdMainnet.bsc]: "https://bscscan.com",
  // Test
  [chainIdTestnet.bscTest]: "https://testnet.bscscan.com",
};

const chainNames = {
  // Main
  [chainIdMainnet.bsc]: "Binance Smart Chain Mainnet",
  // Test
  [chainIdTestnet.bscTest]: "Binance Smart Chain Testnet",
};

const nativeCurrencies: { [key: number]: Currency } = {
  // Main
  [chainIdMainnet.bsc]: { name: "BNB", symbol: "BNB", decimals: 18 },
  // Test
  [chainIdTestnet.bscTest]: { name: "BNB", symbol: "BNB", decimals: 18 },
};

const networks = {
  // BSC
  [chainIdMainnet.bsc]: {
    chainId: chainIdMainnet.bsc,
    chainName: chainNames[chainIdMainnet.bsc],
    nativeCurrency: nativeCurrencies[chainIdMainnet.bsc],
    rpcUrls: [rpcUrls[chainIdMainnet.bsc]],
    blockExplorerUrls: [blockExplorersUrls[chainIdMainnet.bsc]],
  },
  [chainIdTestnet.bscTest]: {
    chainId: chainIdTestnet.bscTest,
    chainName: chainNames[chainIdTestnet.bscTest],
    nativeCurrency: nativeCurrencies[chainIdTestnet.bscTest],
    rpcUrls: [rpcUrls[chainIdTestnet.bscTest]],
    blockExplorerUrls: [blockExplorersUrls[chainIdTestnet.bscTest]],
  },
};

export {
  chainIdMainnet,
  chainIdTestnet,
  rpcUrls,
  blockExplorersUrls,
  chainNames,
  nativeCurrencies,
  networks,
  rpcUrlsForEventsParsing,
  getChainIds,
};
