import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { border, layout, position, space } from "styled-system";

import { useThemeContext } from "context";

import { BoxProps } from "../types";

export const Box = styled.div<BoxProps>`
  background-color: ${({ theme, $backgroundColor }) => $backgroundColor && theme.colors[$backgroundColor]};
  ${border}
  ${layout}
  ${position}
  ${space}
`;

export const Container: React.FC<PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Box px={{ _: 16, laptop: 80 }} mx="auto" maxWidth={theme.siteWidth} {...props}>
      {children}
    </Box>
  );
};
