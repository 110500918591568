export const NAV_LIST = [
  {
    label: "Home",
    link: "main",
  },
  {
    label: "About us",
    link: "our-mission",
  },
  {
    label: "Features",
    link: "features",
  },
  {
    label: "Referral system",
    link: "referral-system",
  },
  {
    label: "Road map",
    link: "road-map",
  },
  {
    label: "Partners",
    link: "partners",
  },
];
