import React from "react";
import { SvgProps } from "components/svg/types";
import { BigNumber } from "@ethersproject/bignumber";

export enum UserProfileTabs {
  GeneralInfo = 0,
  GameStatistics = 1,
  ReferralProgram = 2,
  Tickets = 3,
}

export enum ProfileStatisticsTabs {
  GameWins = 0,
  MonthlyWins = 1,
  WeeklyWins = 2,
}

export interface StatisticItemProps {
  loading: boolean;
  icon: React.FC<SvgProps>;
  label: string;

  value?: number;
  suffix?: string;
  decimals?: number;

  component?: React.ReactNode;
}

export interface PieChartStatisticItemProps {
  loading: boolean;
  pieChartData: PieChartDataItem[];
}

export interface PieChartDataItem {
  title: string;
  value: number;
  color: string;
}

export interface GameUserWin {
  gameId: BigNumber;
  blockId: BigNumber;
  bet: BigNumber;
  user: string;
  reward: BigNumber;
  txHash?: string;
}

export interface ProfileStatisticTabDataItem {
  gamesUserWins: GameUserWin[];
  lastBlockForUser: BigNumber;
}
