import memoize from "lodash/memoize";
import { EN, LOCAL_STORAGE_KEYS, REGEX, languageList } from "configs";

export const fetchLocale = async (locale: string) => {
  try {
    const response = await fetch(`/locales/${locale}.json`);

    const data = await response.json();
    return data;
  } catch (e) {
    console.error(`Failed to fetch locale ${locale}`, e);

    return null;
  }
};

export const getActiveLanguageCode = () => {
  const browserLanguage = window.navigator.language;

  const supportedLanguage = languageList.find(language => language.locale.includes(browserLanguage));
  const codeFromStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.language);

  return codeFromStorage ? codeFromStorage : supportedLanguage ? supportedLanguage.locale : EN.locale;
};

export const translatedTextIncludesVariable = memoize(translatedText => {
  return !!translatedText?.match(REGEX.includesVariableRegex);
});
