import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "pieChartJackpotColor", ...props }) => {
  return (
    <Svg viewBox="0 0 84 77" {...props} color={color}>
      <path d="M26.0868 24.7421L24.9311 24.1443L25.9565 23.3427C27.5645 22.0978 28.8573 20.4923 29.7305 18.6557C30.6036 16.8191 31.0327 14.803 30.9832 12.77C30.8395 5.97988 25.4271 0.37877 18.662 0.0180547C16.8925 -0.075102 15.1225 0.19294 13.4598 0.805855C11.7972 1.41877 10.2767 2.36373 8.99103 3.58317C7.70534 4.80261 6.68135 6.27102 5.98142 7.89894C5.28149 9.52687 4.92027 11.2802 4.91978 13.0522C4.91569 15.0377 5.36682 16.9977 6.2385 18.7816C7.11018 20.5655 8.37921 22.1258 9.94809 23.3427L10.9735 24.1443L9.81783 24.7421C6.90726 26.238 4.46609 28.5083 2.76326 31.3028C1.06042 34.0974 0.162016 37.3077 0.167013 40.5802V42.2502H35.7376V40.5802C35.7426 37.3077 34.8442 34.0974 33.1413 31.3028C31.4385 28.5083 28.9973 26.238 26.0868 24.7421ZM8.18292 13.0539C8.18292 11.1326 8.75265 9.25446 9.82007 7.65696C10.8875 6.05945 12.4046 4.81435 14.1797 4.0791C15.9548 3.34385 17.908 3.15147 19.7924 3.5263C21.6767 3.90113 23.4077 4.82632 24.7662 6.18489C26.1248 7.54346 27.05 9.27437 27.4248 11.1588C27.7996 13.0431 27.6073 14.9964 26.872 16.7714C26.1368 18.5465 24.8917 20.0636 23.2942 21.131C21.6966 22.1985 19.8185 22.7682 17.8972 22.7682C15.3218 22.7651 12.8527 21.7406 11.0316 19.9195C9.21047 18.0984 8.18601 15.6294 8.18292 13.0539ZM3.47858 38.9102L3.66228 37.9216C4.28366 34.6022 6.04605 31.6046 8.64439 29.4475C11.2427 27.2904 14.5135 26.1097 17.8905 26.1097C21.2675 26.1097 24.5383 27.2904 27.1366 29.4475C29.735 31.6046 31.4974 34.6022 32.1187 37.9216L32.3041 38.9102H3.47858Z" />
      <path d="M69.7531 53.5544L68.3403 52.9382L69.6312 52.0948C72.7514 50.0626 75.1314 47.0766 76.4167 43.5818C77.702 40.087 77.8237 36.2705 76.7637 32.7009C75.7037 29.1313 73.5188 25.9998 70.5344 23.7728C67.5501 21.5459 63.9261 20.3428 60.2025 20.3428C56.4789 20.3428 52.8549 21.5459 49.8705 23.7728C46.8862 25.9998 44.7013 29.1313 43.6413 32.7009C42.5813 36.2705 42.703 40.087 43.9883 43.5818C45.2736 47.0766 47.6536 50.0626 50.7738 52.0948L52.0647 52.9382L50.6519 53.5594C46.4221 55.4123 42.823 58.4559 40.2933 62.3191C37.7635 66.1823 36.4126 70.6981 36.4053 75.3159V76.9859H83.9997V75.3159C83.9935 70.6972 82.6432 66.1802 80.1134 62.316C77.5836 58.4518 73.9837 55.4074 69.7531 53.5544ZM46.2398 37.6896C46.2404 34.9387 47.0568 32.2498 48.5855 29.9629C50.1143 27.676 52.2869 25.8937 54.8285 24.8415C57.3702 23.7892 60.1667 23.5143 62.8647 24.0513C65.5626 24.5884 68.0407 25.9134 69.9856 27.8588C71.9305 29.8041 73.2549 32.2825 73.7913 34.9806C74.3277 37.6786 74.0521 40.4751 72.9992 43.0165C71.9464 45.5579 70.1636 47.7301 67.8763 49.2583C65.589 50.7865 62.8999 51.6022 60.149 51.6022C56.4616 51.5982 52.9263 50.1317 50.3187 47.5245C47.7111 44.9172 46.2442 41.382 46.2398 37.6946V37.6896ZM39.772 73.6459L39.8922 72.7041C40.5273 67.7807 42.9338 63.2571 46.6618 59.9791C50.3898 56.7011 55.184 54.8931 60.1482 54.8931C65.1124 54.8931 69.9067 56.7011 73.6346 59.9791C77.3626 63.2571 79.7691 67.7807 80.4042 72.7041L80.5245 73.6459H39.772Z" />
      <path d="M31.0182 65.129L22.9622 73.1833L20.6009 70.8219L24.6239 66.799H0V49.0972H3.33996V63.459H24.6239L20.6009 59.436L22.9622 57.0747L31.0182 65.129Z" />
    </Svg>
  );
};

export default Icon;
