import useSWR from "swr";
import { Zero } from "@ethersproject/constants";
import { BigNumber } from "@ethersproject/bignumber";
import type { SWRConfiguration } from "swr";

import { useActiveWeb3React } from "hooks";
import { getSimpleRpcProvider } from "utils/web3";

export const useGetBalanceByAddress = (address: string | null | undefined, configs?: SWRConfiguration) => {
  const { chainId } = useActiveWeb3React();

  const { data = Zero, isValidating } = useSWR(
    address && chainId ? `${address}/balanceByAddress/${chainId}` : null,
    async () => {
      const simpleRpcProvider = getSimpleRpcProvider(chainId!);
      return await simpleRpcProvider.getBalance(address!);
    },
    configs || {},
  );

  return { balance: data as BigNumber, loading: isValidating };
};
