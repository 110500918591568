import React from "react";
// Hooks
import { useActiveWeb3React, useMatchBreakpoints } from "hooks";
// Context
import { useTranslation } from "context";
// Components
import { Text, Heading, AvatarBlockies, Flex } from "components";
import { CloseIcon } from "components/svg";
import { StyledModalButton } from "./styled";
import { StyledModalWrapper } from "../styled";
import InternalLink from "components/link";
// Store
import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";
// Types
import { GameModalProps } from "./types";
import { linkToRules } from "pages/table/constants";

const GameModal: React.FC = () => {
  const {
    title,
    description,
    buttons,
    rulesDescription,
    loading = false,
  } = useAppSelector(state => state.modal.props as unknown as GameModalProps);
  const { account } = useActiveWeb3React();

  const { isDesktop } = useMatchBreakpoints();

  const { t, currentLanguage } = useTranslation();

  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  return (
    <StyledModalWrapper>
      <Flex justifyContent="end" width="100%">
        <CloseIcon onClick={closeModalHandler} />
      </Flex>
      <AvatarBlockies seed={account ?? ""} scale={8} size={8} my="16px" />
      <Heading textAlign="center" scale={isDesktop ? "h2" : "h4"} as="h4">
        {title}
      </Heading>
      <Text variant="body1" textAlign="center" color="monochrome800" mt="8px" width="70%">
        {description}
      </Text>
      {rulesDescription && (
        <InternalLink external href={linkToRules[currentLanguage.code]}>
          {rulesDescription}
        </InternalLink>
      )}
      <Flex mt="16px" flexDirection={{ _: "column", laptop: "row" }}>
        {buttons.map(({ title, variant = "primary", clickHandler }) => (
          <StyledModalButton
            key={title}
            isLoading={loading}
            scale={isDesktop ? "md" : "sm"}
            variant={variant}
            onClick={clickHandler}
          >
            {/* buttons should be translate inside this component */}
            {t(title)}
          </StyledModalButton>
        ))}
      </Flex>
    </StyledModalWrapper>
  );
};

export default GameModal;
