import { Contract } from "@ethersproject/contracts";
import { useMemo } from "react";

import { getProviderOrSigner, getSimpleRpcProvider } from "utils/web3";

import MULTICALL_ABI from "configs/abi/multicall.json";
import SLOTS_ABI from "configs/abi/slots.json";
import WEEKLY_ABI from "configs/abi/weekly.json";
import MONTHLY_ABI from "configs/abi/monthly.json";
import ORACLE_CHAINLINK_ABI from "configs/abi/oracleChainlink.json";

import { Multicall, Slots, Weekly, Monthly, OracleChainlink } from "configs/abi/types";
import { chainIdMainnet, contractsAddresses, customAddresses, rpcUrlsForEventsParsing } from "configs";
import { getContract } from "./helpers";

import { useActiveWeb3React } from "hooks";

// returns null on errors
const useContract = <T extends Contract = Contract>(
  address: string | undefined,
  ABI: any,
  chainId: number,
  withSignerIfPossible = true,
): T | null => {
  const { library, account } = useActiveWeb3React();

  const signer = useMemo(
    () => (withSignerIfPossible ? getProviderOrSigner(library, account) : null),
    [withSignerIfPossible, library, account],
  );

  const canReturnContract = useMemo(
    () => address && ABI && (withSignerIfPossible ? library : true),
    [address, ABI, library, withSignerIfPossible],
  );

  return useMemo(() => {
    if (!canReturnContract || !chainId) return null;
    try {
      return getContract(address!, ABI, signer, chainId);
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [address, ABI, signer, canReturnContract, chainId]) as T;
};

export function useMulticallContract(chainId: number) {
  return useContract<Multicall>(contractsAddresses.multicall[chainId], MULTICALL_ABI, chainId, false);
}

export function useSlotsContract(chainId: number, signerIfPossible = false) {
  return useContract<Slots>(contractsAddresses.slotsContract[chainId], SLOTS_ABI, chainId, signerIfPossible);
}

export function useWeeklyLotteryContract(chainId: number) {
  return useContract<Weekly>(contractsAddresses.weeklyLotteryContract[chainId], WEEKLY_ABI, chainId, false);
}

export function useMonthlyLotteryContract(chainId: number) {
  return useContract<Monthly>(contractsAddresses.monthlyLotteryContract[chainId], MONTHLY_ABI, chainId, false);
}
// Contracts with rpc links
export function useSlotsContractFromCustomRPC(chainId: number) {
  const signer = getSimpleRpcProvider(chainId, rpcUrlsForEventsParsing[chainId]);
  return getContract(contractsAddresses.slotsContract[chainId], SLOTS_ABI, signer, chainId) as Slots;
}

export function useWeeklyLotteryContractFromCustomRPC(chainId: number) {
  const signer = getSimpleRpcProvider(chainId, rpcUrlsForEventsParsing[chainId]);
  return getContract(contractsAddresses.weeklyLotteryContract[chainId], WEEKLY_ABI, signer, chainId) as Weekly;
}

export function useMonthlyLotteryContractFromCustomRPC(chainId: number) {
  const signer = getSimpleRpcProvider(chainId, rpcUrlsForEventsParsing[chainId]);
  return getContract(contractsAddresses.monthlyLotteryContract[chainId], MONTHLY_ABI, signer, chainId) as Monthly;
}

export function useChainlinkOracleContract() {
  return useContract<OracleChainlink>(
    customAddresses.chainlinkOracle,
    ORACLE_CHAINLINK_ABI,
    chainIdMainnet.mainnet,
    false,
  );
}
