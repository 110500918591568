import styled from "styled-components";
import { Flex } from "components";

export const StyledCard = styled(Flex)<{ isSelected?: boolean }>`
  padding: 12px 24px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.monochrome400};
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  margin: 7px 0;
  align-items: center;

  &:hover {
    background-color: ${({ theme, isSelected }) => !isSelected && theme.colors.monochrome400};
    cursor: ${({ isSelected }) => !isSelected && "pointer"};
  }

  background-color: ${({ theme, isSelected }) => isSelected && theme.colors.monochrome500};
  cursor: ${({ isSelected }) => isSelected && "not-allowed"};
`;
