import { Language } from "context/language-context/types";

export const EN: Language = { locale: "en-US", language: "English", code: "en", label: "ENG" };
export const RU: Language = { locale: "ru-RU", language: "Русский", code: "ru", label: "RU" };

export const languages = {
  ru: RU,
  en: EN,
};

export const languageList = Object.values(languages);
