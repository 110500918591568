import { combineReducers } from "redux";

import modal from "store/modal";
import profile from "./profile/reducer";
import multicall from "./multicall/reducer";
import nominals from "store/nominals";
import transactions from "./transactions/reducer";

export const rootReducer = combineReducers({
  [modal.name]: modal.reducer,
  [profile.name]: profile.reducer,
  multicall: multicall,
  transactions: transactions,
  nominals: nominals.reducer,
});

export default rootReducer;
