import { TranslateFunction } from "context/language-context/types";

const URL = process.env.REACT_APP_URL;

export const getDefaultMeta = (t: TranslateFunction) => {
  return {
    title: "",
    description: t("iGames slots"),
    image: `${URL}/logo512.png`,
  };
};

export const getCustomMeta = (path: string, t: TranslateFunction) => {
  switch (path) {
    case "registration": {
      return {
        ...getDefaultMeta(t),
        title: t("Registration"),
      };
    }
    case "main": {
      return {
        ...getDefaultMeta(t),
        title: t("Main"),
      };
    }
    case "game-table": {
      return {
        ...getDefaultMeta(t),
        title: t("Table"),
      };
    }
    case "profile": {
      return {
        ...getDefaultMeta(t),
        title: t("User profile"),
      };
    }
    default:
      return {
        ...getDefaultMeta(t),
      };
  }
};
