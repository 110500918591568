import styled from "styled-components";
import { Button } from "components";

export const StyledModalButton = styled(Button)`
  width: 180px;

  :nth-of-type(1) {
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 240px;
    margin-right: 8px;
  }
`;
