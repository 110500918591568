import { DefaultTheme } from "styled-components";

export const variants = {
  error: "error",
  warning: "warning",
  success: "success",
} as const;

export interface ThemedProps extends TableFullnessProps {
  theme: DefaultTheme;
}

export interface TableFullnessProps {
  currentFullness: number;
  maxFullness: number;
}
