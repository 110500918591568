import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "accent500", fill = "monochrome1000", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 35 12" {...props} color={color}>
      <path
        d="M0 2C0 0.895431 0.895431 0 2 0H32.6664C33.771 0 34.6664 0.895431 34.6664 2V10C34.6664 11.1046 33.771 12 32.6664 12H2C0.895431 12 0 11.1046 0 10V2Z"
        fill={theme.colors[color]}
      />
      <path d="M15.5649 8.21325V9.31293H10.9639V2.68652H12.16V8.21325H15.5649Z" fill={theme.colors[fill]} />
      <path d="M16.5586 2.68652H17.7559V9.31293H16.5586V2.68652Z" fill={theme.colors[fill]} />
      <path
        d="M25.5289 2.68652L22.7598 9.31293H21.4199L18.6514 2.68652H19.9826L22.0855 7.71195L24.1883 2.68652H25.5289Z"
        fill={theme.colors[fill]}
      />
      <path
        d="M27.613 3.7862V5.38749H31.0372V6.48686H27.613V8.21355H31.3941V9.31323H26.4072V2.68652H31.3941V3.7862H27.613Z"
        fill={theme.colors[fill]}
      />
      <path
        d="M5.33407 7.80865C6.33298 7.80865 7.14275 6.99887 7.14275 5.99997C7.14275 5.00106 6.33298 4.19128 5.33407 4.19128C4.33516 4.19128 3.52539 5.00106 3.52539 5.99997C3.52539 6.99887 4.33516 7.80865 5.33407 7.80865Z"
        fill={theme.colors[fill]}
      />
    </Svg>
  );
};

export default Icon;
