export const winnerPluralize = (place: number) => {
  switch (place) {
    case 1:
      return `${place}-st`;
    case 2:
      return `${place}-nd`;
    case 3:
      return `${place}-rd`;
    default:
      return `${place}-th`;
  }
};

export const winnerPluralizeRu = (place: number) => {
  return `${place}-е`;
};
