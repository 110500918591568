import { useMemo } from "react";
import { Zero } from "@ethersproject/constants";

import { useActiveWeb3React, useSlotsContract, useWeeklyLotteryContract, useSingleContractMultipleData } from "hooks";
import { ListenerOptions } from "hooks/use-multicall/types";

import { ZERO_ADDRESS } from "utils/web3";
import { showedNominals } from "./constants";
import { BigNumber } from "@ethersproject/bignumber";
import { useAppSelector } from "store/store";

const useNominalsInfo = ({ blocksPerFetch }: ListenerOptions) => {
  const { chainId, account } = useActiveWeb3React();
  const weeklyContract = useWeeklyLotteryContract(chainId);

  const slotsContract = useSlotsContract(chainId);
  const lastWeeklyLotteryBanks = useAppSelector(({ nominals }) => nominals.weekBanks);

  const allFounds = useSingleContractMultipleData(
    weeklyContract,
    "slotsToFunds",
    showedNominals.map(nominal => [nominal.nominalWEI]),
    { blocksPerFetch },
  );

  const allGamesInfo = useSingleContractMultipleData(
    slotsContract,
    "getGame",
    showedNominals.map(nominal => [nominal.nominalWEI]),
    { blocksPerFetch },
  );

  const allNominalsInfo = useMemo(() => {
    return showedNominals.map((nominal, index) => {
      const weekBank: BigNumber = allFounds[index]?.result?.[0] || Zero;
      const lastLotteryBank = lastWeeklyLotteryBanks[nominal.nominalBNB];
      const players = allGamesInfo[index]?.result?.[0]["players"] ?? [];
      const filteredPlayers: string[] = players.filter((player: string) => player !== ZERO_ADDRESS);
      const isUserExist = filteredPlayers.length !== 10 && filteredPlayers.some(player => player === account);

      return { ...nominal, lastLotteryBank, weekBank, players: filteredPlayers || [], isUserExist };
    });
  }, [allGamesInfo, allFounds]);

  const anyLoading = useMemo(
    () => [...allGamesInfo, ...allFounds].some(callState => callState.loading),
    [allGamesInfo, allFounds],
  );

  return {
    allNominalsInfo,
    anyLoading,
  };
};

export { useNominalsInfo, showedNominals };
