import { memo, useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import FlipNumbers from "react-flip-numbers";
// Context
import { useThemeContext, useTranslation } from "context";
// Hooks
import { useInterval } from "hooks";
// Components
import { Flex, Text, Heading } from "components";
// Types
import { LotteryCountDownProps } from "./types";
// Utils
import { capitalizeString, getTimePeriods } from "utils/helpers";

// timestamp in seconds, that comes from contract in future date
const LotteryCountDown: React.FC<LotteryCountDownProps> = ({
  timestamp,
  onReachZeroCallback,
  keyListShow = ["days", "hours", "mins", "sec"],
  headingScale = "h1",
  textVariant = "decor1",
  isText = false,
  isHideTimerLabels = false,
  numberWidth = 48,
  numberHeight = 48,
  perspective,
  color = "monochrome1000",
  ...props
}) => {
  // if timestamp come from past return current time
  const remainingTimestampSeconds =
    +(timestamp - moment().valueOf() / 1000) < 0 ? 0 : +(timestamp - moment().valueOf() / 1000);
  const initTimeRemaining = useMemo(() => (timestamp === 0 ? 0 : +remainingTimestampSeconds.toFixed()), [timestamp]);
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const [remainingSeconds, setRemainingSeconds] = useState(initTimeRemaining);

  const timer = getTimePeriods(remainingSeconds);

  const updateRemainingSeconds = useCallback(() => {
    setRemainingSeconds(prev => {
      return prev - 1;
    });
  }, []);

  useEffect(() => {
    if (remainingSeconds === 0 && initTimeRemaining !== 0) {
      onReachZeroCallback();
    }
  }, [remainingSeconds, initTimeRemaining]);

  useInterval(updateRemainingSeconds, remainingSeconds === 0 ? null : 1 * 1000);

  const formatTimerValue = (value: number): string => {
    return value.toString().length === 1 ? `0${value}` : value.toString();
  };

  const SelectedComponentWrapper = isText ? Text : Heading;
  const componentWrapperProps = isText ? { variant: textVariant } : { scale: headingScale };

  return (
    <Flex justifyContent="space-between" {...props}>
      {Object.keys(timer).map(keyItem => {
        if (keyListShow.includes(keyItem)) {
          return (
            <Flex key={keyItem} flexDirection="column" alignItems="center" justifyContent="center">
              <SelectedComponentWrapper
                key={keyItem}
                {...componentWrapperProps}
                as="div"
                // Need flex & flex grow for centralized Sec timer label
                display="flex"
                style={{ flexGrow: "1" }}
              >
                <FlipNumbers
                  play
                  width={numberWidth}
                  height={numberHeight}
                  perspective={perspective}
                  color={theme.colors[color]}
                  numbers={formatTimerValue(timer[keyItem as keyof typeof timer]) + (keyItem === "sec" ? "" : ":")}
                />
              </SelectedComponentWrapper>

              {!isHideTimerLabels && (
                <Heading scale="h6" width="100%" textTransform="uppercase" text-align="left" color="monochrome700">
                  {capitalizeString(t(keyItem))}
                </Heading>
              )}
            </Flex>
          );
        }
      })}
    </Flex>
  );
};

export default memo(LotteryCountDown);
