import { lazyLoad } from "utils/loadable";

export const LandingPage = lazyLoad(
  () => import("./landing"),
  module => module.default,
);

export const MainPage = lazyLoad(
  () => import("./main"),
  module => module.default,
);

export const RegistrationPage = lazyLoad(
  () => import("./registration"),
  module => module.default,
);

export const TablePage = lazyLoad(
  () => import("./table"),
  module => module.default,
);

export const ProfilePage = lazyLoad(
  () => import("./profile"),
  module => module.default,
);

export const LotteryPage = lazyLoad(
  () => import("./lottery"),
  module => module.default,
);
