import { formatUnits, parseUnits } from "@ethersproject/units";
import { chainIdTestnet } from "configs/networks";

export const allNominals = [
  {
    nominalBNB: "0.2",
    nominalWEI: formatUnits(parseUnits("0.2"), "wei"),
  },
  {
    nominalBNB: "0.3",
    nominalWEI: formatUnits(parseUnits("0.3"), "wei"),
  },
  {
    nominalBNB: "0.4",
    nominalWEI: formatUnits(parseUnits("0.4"), "wei"),
  },
  {
    nominalBNB: "0.5",
    nominalWEI: formatUnits(parseUnits("0.5"), "wei"),
  },
  {
    nominalBNB: "0.75",
    nominalWEI: formatUnits(parseUnits("0.75"), "wei"),
  },
  {
    nominalBNB: "1",
    nominalWEI: formatUnits(parseUnits("1"), "wei"),
  },
  {
    nominalBNB: "1.5",
    nominalWEI: formatUnits(parseUnits("1.5"), "wei"),
  },
  {
    nominalBNB: "2.5",
    nominalWEI: formatUnits(parseUnits("2.5"), "wei"),
  },
  {
    nominalBNB: "5",
    nominalWEI: formatUnits(parseUnits("5"), "wei"),
  },
  {
    nominalBNB: "10",
    nominalWEI: formatUnits(parseUnits("10"), "wei"),
  },
];

export const showedNominals =
  process.env.REACT_APP_CHAIN_ID === chainIdTestnet.bscTest.toString()
    ? [
        {
          nominalBNB: "0.001",
          nominalWEI: formatUnits(parseUnits("0.001"), "wei"),
        },
        ...allNominals,
      ]
    : allNominals;
