import { Flex } from "components/layout";
import styled from "styled-components";

export const StyledModalWrapper = styled(Flex)`
  max-width: 600px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
