import { useEffect, useMemo } from "react";
import { Zero } from "@ethersproject/constants";
import { BigNumber } from "@ethersproject/bignumber";
//Hooks
import {
  useActiveWeb3React,
  useWeeklyLotteryContract,
  useSWRContract,
  useSingleCallResult,
  useNominalsInfo,
} from "hooks";
// Store
import { useAppDispatch, useAppSelector } from "store/store";
import { checkedNominalTimestamp } from "store/nominals";
// Constants
import { showedNominals } from "./use-nominals-info/constants";
import { ListenerOptions } from "./use-multicall/types";
import { blocksPerFetch } from "utils/web3";

/**
 * @argument nominal should be in wei
 */
export const useWeeklyLotteryStatistics = (
  nominalWEI: string = showedNominals[0].nominalWEI,
  configs: ListenerOptions = { blocksPerFetch },
) => {
  const { chainId } = useActiveWeb3React();
  const weeklyLotteryContract = useWeeklyLotteryContract(chainId);

  const dispatch = useAppDispatch();
  // Array of bets(nominals)
  const { allNominalsInfo } = useNominalsInfo(configs);
  const nominal =
    showedNominals.find(nominal => nominal.nominalWEI === nominalWEI)?.nominalBNB ?? showedNominals[0].nominalBNB;

  const isActiveNominal = useAppSelector(({ nominals }) => nominals.timestamps[nominal ?? "monthly"]);

  // Timestamp in seconds for the next lottery to draw
  // Should be revalidated on focus
  const { data = Zero, isValidating, mutate } = useSWRContract([weeklyLotteryContract, "nextLotteryTimestamps"]);

  // Users that participate at the current draw for the current nominal
  const usersCount: BigNumber =
    useSingleCallResult(weeklyLotteryContract, "uniqueUsersCount", [nominalWEI], configs).result?.[0] || Zero;

  useEffect(() => {
    if (isActiveNominal) {
      dispatch(checkedNominalTimestamp({ nominal, isChecked: false }));
      mutate();
    }
  }, [isActiveNominal]);

  // Balance of the contract for all of nominals
  const weeklyLotteryBalance = useMemo(() => {
    const foundNominal = allNominalsInfo.find(nominalData => nominalData.nominalWEI === nominalWEI);
    return foundNominal ? foundNominal.weekBank : Zero;
  }, [allNominalsInfo, nominalWEI]);

  return {
    weeklyTimestamp: data,
    loading: isValidating,
    refreshTimestamp: mutate,
    allNominalsInfo,
    weeklyLotteryBalance,
    usersCount,
  };
};
