import { useMemo } from "react";
import { BigNumber } from "@ethersproject/bignumber";
import { Zero } from "@ethersproject/constants";
// Hooks
import { useSingleContractMultipleData, useSWRContract } from "hooks";
// Types
import { Monthly, Slots, Weekly } from "configs/abi/types";
import { blocksPerFetch } from "utils/web3";

export const useGetLastDrawBlock = (contract: Weekly | Monthly | Slots | null, params?: string | null) => {
  const {
    data = Zero,
    isValidating,
    mutate,
  } = useSWRContract([contract, "lastDrawBlock", params ? [params] : []], { revalidateOnFocus: false });

  return {
    lastDrawBlock: data,
    loading: isValidating,
    getNewBlock: mutate,
  };
};

export const useGetLastDrawBlockForUser = (
  contract: Weekly | Monthly | Slots | null,
  address: string,
  nominalWEI?: string | undefined,
) => {
  const {
    data = Zero,
    isValidating,
    mutate,
  } = useSWRContract([contract, "lastDrawBlockForUser", nominalWEI ? [nominalWEI, address] : [address]], {
    revalidateOnFocus: false,
  });

  return {
    lastDrawBlockForUser: data,
    loading: isValidating,
    getNewBlock: mutate,
  };
};

export const useGetMultiplyLastDrawBlock = (
  contract: Weekly | Monthly | Slots | null,
  nominals: {
    nominalBNB: string;
    nominalWEI: string;
  }[],
) => {
  const lastDrawBlock = useSingleContractMultipleData(
    contract,
    "lastDrawBlock",
    nominals.map(nominal => [nominal.nominalWEI]),
    { blocksPerFetch },
  );

  const anyLoading = useMemo(() => [...lastDrawBlock].some(callState => callState.loading), [lastDrawBlock]);

  const lastDrawBlockList = lastDrawBlock.map((lastDraw, index) => {
    const lastDrawBlock: BigNumber = lastDraw.result?.[0] || Zero;

    return {
      lastDrawBlock,
      ...nominals[index],
    };
  });

  return {
    lastDrawBlockList,
    anyLoading,
  };
};
