import { Colors } from "theme/types";

export const getWinnerColor = (place: number): keyof Colors => {
  switch (place) {
    case 1:
      return "gold";
    case 2:
      return "silver";
    case 3:
      return "bronze";
    default:
      return "monochrome1000";
  }
};
