import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Twitter: React.FC<SvgProps> = props => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#clip0_834_3442)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#78BAEB"
        />
        <path
          d="M12.0001 0C11.4696 0 10.9472 0.034875 10.4349 0.101578C16.3227 0.868594 20.8696 5.90311 20.8696 12C20.8696 18.0969 16.3226 23.1314 10.4348 23.8984C10.9472 23.9651 11.4696 24 12.0001 24C18.6275 24 24.0001 18.6274 24.0001 12C24.0001 5.37262 18.6275 0 12.0001 0Z"
          fill="#59AAE7"
        />
        <path
          d="M9.66508 18.8088C8.56296 18.8088 7.48291 18.6105 6.45494 18.2196L5.43626 17.8322C5.32483 17.7898 5.34026 17.6276 5.45768 17.6069L6.53116 17.4184C7.52571 17.2438 8.46621 16.8388 9.27227 16.2409C9.3573 16.1778 9.32304 16.0451 9.21827 16.0297C8.27965 15.892 7.43693 15.3244 6.9641 14.4915L6.72311 14.067C6.6799 13.9909 6.73221 13.8961 6.81954 13.8919L7.30708 13.8686C7.30872 13.8685 7.31032 13.8684 7.31201 13.8683C7.43444 13.8622 7.46369 13.6955 7.35237 13.644C6.45921 13.2306 5.81219 12.4003 5.64597 11.4061L5.57168 10.9617C5.55785 10.8791 5.63262 10.809 5.71418 10.8281L6.15283 10.9308C6.38632 10.9855 6.62768 11.0133 6.87026 11.0133C6.87312 11.0133 6.87597 11.0133 6.87888 11.0133C5.94344 10.4141 5.38774 9.40155 5.38774 8.287C5.38774 8.00979 5.42252 7.73557 5.49119 7.47194L5.62235 6.96822C5.64513 6.88071 5.75416 6.8508 5.81843 6.91432L6.1886 7.28032C7.16622 8.24697 8.36665 8.99111 9.66035 9.43239C10.0029 9.541 11.0417 9.83571 11.4013 9.83716L11.4102 9.83725C11.4115 9.83725 11.5152 9.841 11.5826 9.84414C11.5822 9.82521 11.5818 9.80627 11.5818 9.78729C11.5818 7.82847 13.1755 6.23486 15.1343 6.23486C16.0696 6.23486 16.9478 6.59318 17.6153 7.24558C17.6417 7.27136 17.6784 7.28393 17.7149 7.27839C18.0581 7.22632 18.3997 7.14415 18.7281 7.03413L19.8233 6.66719C19.9381 6.62875 20.0288 6.76811 19.9473 6.8575L19.1692 7.71105C19.0655 7.82477 18.9542 7.93061 18.8358 8.02797C18.742 8.10508 18.8118 8.25672 18.9311 8.23432C19.0371 8.21444 19.1426 8.19194 19.2469 8.16691L20.2515 7.92588C20.3658 7.8985 20.4449 8.0373 20.3631 8.12168L19.6439 8.86333C19.3527 9.16361 19.0299 9.43919 18.6843 9.68252L18.6848 9.69949C18.6858 9.72864 18.6867 9.75785 18.6867 9.78729V9.82268C18.6664 14.7784 14.6193 18.8088 9.66508 18.8088Z"
          fill="#FCFCFC"
        />
      </g>
      <defs>
        <clipPath id="clip0_834_3442">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Twitter;
