import Blockies from "react-blockies";
import styled from "styled-components";
import { space } from "styled-system";
import { AvatarBlockiesProps } from "./types";

export const StyledBlockies = styled(Blockies)<{ variant?: AvatarBlockiesProps["variant"] }>`
  border-radius: ${({ theme, variant }) => (variant === "circle" ? theme.radii.circle : "0px")};
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.accent100};

  ${space}
`;
