import { connectorName } from "utils/web3";
import { SvgProps } from "components/svg/types";
import { CoinBase, WalletConnect, Web3 } from "components/svg";

const URL = process.env.REACT_APP_URL as string;

export interface Connector {
  title: string;
  icon: React.FC<SvgProps>;
  connectorId: string;
  href?: string;
}

export const connectors = [
  {
    title: "Web3",
    icon: Web3,
    href: `https://metamask.app.link/dapp/${URL?.replace("https://", "")}/`,
    connectorId: connectorName.injectedConnector,
  },
  {
    title: "Wallet connect",
    icon: WalletConnect,
    connectorId: connectorName.walletConnect,
  },
  {
    title: "Coinbase",
    icon: CoinBase,
    connectorId: connectorName.walletLinkConnector,
  },
];
