import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "error500", ...props }) => {
  return (
    <Svg viewBox="0 0 20 20" {...props} color={color}>
      <path d="M13.4814 14.235C13.1309 14.235 12.8467 14.5192 12.8467 14.8698V16.6471H3.76953V2.93616H12.8467V4.7135C12.8467 5.06408 13.1309 5.34827 13.4814 5.34827C13.832 5.34827 14.1162 5.06408 14.1162 4.7135V2.30139C14.1162 1.95081 13.832 1.66663 13.4814 1.66663H3.13477C2.78418 1.66663 2.5 1.95081 2.5 2.30139V17.2819C2.5 17.6324 2.78418 17.9166 3.13477 17.9166H13.4814C13.832 17.9166 14.1162 17.6324 14.1162 17.2819V14.8698C14.1162 14.5192 13.832 14.235 13.4814 14.235Z" />
      <path d="M17.6119 9.34271L15.9063 7.63709C15.6585 7.38918 15.2565 7.38921 15.0086 7.63709C14.7607 7.88497 14.7607 8.2869 15.0086 8.53481L15.6306 9.15678L10.3723 9.15675C10.0218 9.15675 9.73755 9.44094 9.73755 9.79152C9.73755 10.1421 10.0217 10.4263 10.3723 10.4263L15.6307 10.4263L15.0087 11.0484C14.7608 11.2962 14.7608 11.6981 15.0087 11.946C15.2565 12.1939 15.6585 12.1939 15.9064 11.946L17.612 10.2404C17.612 10.2403 17.6121 10.2403 17.6121 10.2402C17.7398 10.1124 17.7933 9.96468 17.7979 9.79155C17.7979 9.62042 17.7252 9.45589 17.6119 9.34271Z" />
    </Svg>
  );
};

export default Icon;
