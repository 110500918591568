import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
// Context
import { useTranslation } from "context";
// Components
import { Text, Heading } from "components";
import { AddIcon, MinusIcon } from "components/svg";
// Types
import { ExpansionPanelProps } from "./types";
// Styles
import { StyledExpansionPanel, StyledExpansionPanelLabelWrapper, StyledExpansionContentWrapper } from "./styled";

const EXPANSION_TRANSITION = { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] };

const ExpansionPanel: React.FC<ExpansionPanelProps> = ({
  label,
  textVariant,
  headingScale,
  isLabelAsText = false,
  children,
  ...props
}) => {
  const { t } = useTranslation();

  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const SelectedComponentWrapper = isLabelAsText ? Text : Heading;
  const componentWrapperProps = isLabelAsText ? { variant: textVariant } : { scale: headingScale };

  return (
    <StyledExpansionPanel>
      <StyledExpansionPanelLabelWrapper
        initial={false}
        onClick={() => {
          setIsPanelOpen(!isPanelOpen);
        }}
        isPanelOpen={isPanelOpen}
      >
        <SelectedComponentWrapper {...componentWrapperProps} {...props}>
          {t(label)}
        </SelectedComponentWrapper>
        {isPanelOpen ? <MinusIcon width="24px" /> : <AddIcon width="24px" />}
      </StyledExpansionPanelLabelWrapper>

      <AnimatePresence initial={false}>
        {isPanelOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={EXPANSION_TRANSITION}
          >
            <StyledExpansionContentWrapper
              variants={{ collapsed: { opacity: 0, scale: 0.1 }, open: { opacity: 1, scale: 1 } }}
              transition={EXPANSION_TRANSITION}
            >
              {children}
            </StyledExpansionContentWrapper>
          </motion.section>
        )}
      </AnimatePresence>
    </StyledExpansionPanel>
  );
};

export default ExpansionPanel;
