import styled from "styled-components";
import { Text } from "components";
import { getHeadingSettings } from "./theme";
import { scales, HeadingProps } from "./types";

const Heading = styled(Text)<HeadingProps>`
  ${getHeadingSettings}
`;

Heading.defaultProps = {
  as: scales.h1,
  scale: scales.h1,
};

export default Heading;
