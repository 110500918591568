import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Constants
import { showedNominals } from "hooks/use-nominals-info/constants";
import { INITIAL_STATISTIC_TAB_DATA_ITEM } from "pages/profile/constants";
// Types
import { ProfileStatisticsTabs, ProfileStatisticTabDataItem, UserProfileTabs } from "pages/profile/types";
import { ProfileState, ProfileStatisticTabsData, UpdateTabDataItemPayload } from "./types";

const getInitialTabsList = () => {
  const nominalsList = showedNominals.map(nominal => nominal.nominalBNB);
  const generatedInitialList: { [key: string]: ProfileStatisticTabDataItem } = {};

  nominalsList.forEach(nominal => {
    generatedInitialList[nominal] = INITIAL_STATISTIC_TAB_DATA_ITEM;
  });

  return generatedInitialList;
};

const getCurrentNominalByIndex = (index: number) => {
  // For situation, when tabsData for activeStatisticTab don't have division into nominals
  return index >= 0 ? showedNominals[index].nominalBNB : "0";
};

const initialState: ProfileState = {
  activeTab: UserProfileTabs.GeneralInfo,
  activeStatisticTab: ProfileStatisticsTabs.GameWins,
  savedNominalsTabs: {
    [ProfileStatisticsTabs.GameWins]: 0,
    [ProfileStatisticsTabs.MonthlyWins]: -1,
    [ProfileStatisticsTabs.WeeklyWins]: 0,
  },
  activeNominalIndexTab: 0,
  limit: 10,

  tabsData: {
    [ProfileStatisticsTabs.GameWins]: getInitialTabsList(),
    [ProfileStatisticsTabs.MonthlyWins]: { "0": INITIAL_STATISTIC_TAB_DATA_ITEM },
    [ProfileStatisticsTabs.WeeklyWins]: getInitialTabsList(),
  },
};

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    ...initialState,
  },
  reducers: {
    setActiveTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload as UserProfileTabs;
    },
    setActiveStatisticTab: (state, action: PayloadAction<number>) => {
      state.activeStatisticTab = action.payload as ProfileStatisticsTabs;
      if (action.payload === ProfileStatisticsTabs.MonthlyWins) {
        // If MonthlyWins tab is active, need to set activeNominalIndexTab = -1 (for correct getting and update tabsData, because MonthlyWins tabsData don't have division into nominals )
        state.activeNominalIndexTab = -1;
      } else {
        state.activeNominalIndexTab = state.savedNominalsTabs[state.activeStatisticTab];
      }
    },
    setActiveNominalTab: (state, action: PayloadAction<number>) => {
      state.savedNominalsTabs = { ...state.savedNominalsTabs, [state.activeStatisticTab]: action.payload };
      state.activeNominalIndexTab = action.payload;
    },
    setTabsData: (state, action: PayloadAction<ProfileStatisticTabsData>) => {
      state.tabsData = action.payload;
    },
    updateActiveTabDataItem: (state, action: PayloadAction<Partial<ProfileStatisticTabDataItem>>) => {
      state.tabsData = {
        ...state.tabsData,
        [state.activeStatisticTab]: {
          ...state.tabsData[state.activeStatisticTab],
          [getCurrentNominalByIndex(state.activeNominalIndexTab)]: {
            ...state.tabsData[state.activeStatisticTab][getCurrentNominalByIndex(state.activeNominalIndexTab)],
            ...action.payload,
          },
        },
      };
    },
    updateTabDataItem: (state, action: PayloadAction<UpdateTabDataItemPayload>) => {
      const { selectedStatisticTab, selectedNominalIndexTab, data } = action.payload;
      state.tabsData = {
        ...state.tabsData,
        [selectedStatisticTab]: {
          ...state.tabsData[selectedStatisticTab],
          [getCurrentNominalByIndex(selectedNominalIndexTab)]: {
            ...state.tabsData[selectedStatisticTab][getCurrentNominalByIndex(selectedNominalIndexTab)],
            ...data,
          },
        },
      };
    },

    resetTabsData: state => {
      state.tabsData = { ...initialState.tabsData };
    },
    resetProfileStore: state => {
      state.activeTab = initialState.activeTab;
      state.activeStatisticTab = initialState.activeStatisticTab;
      state.activeNominalIndexTab = initialState.activeNominalIndexTab;
      state.limit = initialState.limit;

      state.tabsData = { ...initialState.tabsData };
    },
  },
});

export const {
  setActiveTab,
  setActiveStatisticTab,
  setActiveNominalTab,
  setTabsData,
  updateActiveTabDataItem,
  updateTabDataItem,
  resetTabsData,
  resetProfileStore,
} = profileSlice.actions;
export default profileSlice;
