import { chainIdMainnet, chainIdTestnet } from "configs/networks";

const contractsAddresses = {
  multicall: {
    [chainIdMainnet.bsc]: "0xC50F4c1E81c873B2204D7eFf7069Ffec6Fbe136D",
    [chainIdTestnet.bscTest]: "0x73CCde5acdb9980f54BcCc0483B28B8b4a537b4A",
  },
  slotsContract: {
    [chainIdMainnet.bsc]: "0x7fe77883B6C33e5592780eFe04c89f4d8c57dCD5",
    [chainIdTestnet.bscTest]: "0x1E19d399b048Ba582f0c846927b0f09bE2699A0c",
  },
  weeklyLotteryContract: {
    [chainIdMainnet.bsc]: "0x3DBE5435F2f02E121DD6AD36b702ad69f4D2143B",
    [chainIdTestnet.bscTest]: "0x51488e9A158e585Acfe93D904b39D885A7A1Bc17",
  },
  monthlyLotteryContract: {
    [chainIdMainnet.bsc]: "0x91EB4f6A2cf89E4E990B935B84ddCa04F82Ed758",
    [chainIdTestnet.bscTest]: "0xF377939abC5061a8Fe05AC7b522e04da7c3F7A54",
  },
};

const customAddresses = {
  chainlinkOracle: "0x14e613AC84a31f709eadbdF89C6CC390fDc9540A",
};

export { contractsAddresses, customAddresses };
