import React from "react";
import CountUp from "react-countup";
// Context
import { useTranslation } from "context";
// Components
import { Text } from "components";
// Types
import { TableFullnessProps } from "./types";
// Styles
import { StyledTableFullness } from "./styled";

const TableFullness: React.FC<TableFullnessProps> = ({ currentFullness = 0, maxFullness = 0 }) => {
  const { t } = useTranslation();

  return (
    <StyledTableFullness currentFullness={currentFullness} maxFullness={maxFullness}>
      <Text variant="smallText">
        <CountUp
          end={currentFullness}
          separator=","
          suffix={` ${t("of")} ${maxFullness}`}
          preserveValue
          scrollSpyOnce
          duration={0.5}
        />
      </Text>
    </StyledTableFullness>
  );
};

export default TableFullness;
