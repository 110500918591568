import { Zero } from "@ethersproject/constants";
import { showedNominals } from "hooks/use-nominals-info/constants";
import { ProfileStatisticTabDataItem } from "./types";

export const USER_PROFILE_TABS = ["General information", "Game statistics", "Referral program", "Tickets"];

export const PROFILE_STATISTICS_TABS = ["Game wins", "Monthly wins", "Weekly Wins"];

export const PROFILE_NOMINALS_TABS = showedNominals.map(nominal => nominal?.nominalBNB + " BNB");

export const INITIAL_STATISTIC_TAB_DATA_ITEM: ProfileStatisticTabDataItem = {
  gamesUserWins: [],
  lastBlockForUser: Zero,
};
