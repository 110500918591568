import styled from "styled-components";
import { flexbox, layout, space, SpaceProps } from "styled-system";

import { Box } from "../container";

import { AutoRowProps, RowProps } from "../types";

export const Row = styled(Box)<RowProps>`
  display: flex;
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  ${space}
  ${layout}
  ${flexbox}
`;

export const RowBetween = styled(Row)<SpaceProps>`
  justify-content: space-between;
  ${space}
`;

export const RowFlat = styled.div<SpaceProps>`
  display: flex;
  align-items: flex-end;
  ${space}
`;

export const AutoRow = styled(Row)<AutoRowProps>`
  flex-wrap: wrap;
  margin: ${({ gap }) => gap && `-${gap}`};
  justify-content: ${({ justify }) => justify};

  & > * {
    margin: ${({ gap }) => gap} !important;
  }
`;
