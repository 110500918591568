import styled from "styled-components";
import { Flex } from "components/layout";

export const StyledFlexWrapper = styled(Flex)`
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  word-wrap: break-word;
`;
