import moment from "moment";
import { useMemo } from "react";
import { BLOCKS_IN_FIVE_MINUTES } from "utils/constants";
import { formatBigNumberToFixed } from "utils/web3";
import {
  showedNominals,
  useActiveWeb3React,
  useCurrentBlock,
  useGetLastDrawBlock,
  useMonthlyLotteryContract,
  useMonthlyLotteryStatistics,
  useWeeklyLotteryContract,
  useWeeklyLotteryStatistics,
} from "../hooks";

export const useGetLotteriesAwait = () => {
  const { chainId } = useActiveWeb3React();

  const weeklyContract = useWeeklyLotteryContract(chainId);
  const monthlyContract = useMonthlyLotteryContract(chainId);
  const activeNominal = showedNominals[0].nominalWEI ?? null;

  const blockNumber = useCurrentBlock();

  // count of seconds in five minutes
  const fiveMinutesSeconds = 300;

  const { monthlyTimestamp, loading: monthlyLoading } = useMonthlyLotteryStatistics();
  const { weeklyTimestamp, loading: weeklyLoading } = useWeeklyLotteryStatistics();
  const { lastDrawBlock: lastWeeklyBlock, loading: monthlyBlockLoading } = useGetLastDrawBlock(
    weeklyContract,
    activeNominal,
  );
  const { lastDrawBlock: lastMonthlyBlock, loading: weeklyBlockLoading } = useGetLastDrawBlock(monthlyContract, null);

  const currentTime = moment().valueOf() / 1000;

  const isMonthlyLotteryIncome = useMemo(() => {
    if (!monthlyBlockLoading && !monthlyLoading) {
      const lastLotteryBlock = +formatBigNumberToFixed(lastMonthlyBlock, 0, 0);
      const nextMonthlyTimestamp = +formatBigNumberToFixed(monthlyTimestamp, 0, 0);
      if (nextMonthlyTimestamp === 0) {
        return false;
      }
      return (
        nextMonthlyTimestamp - currentTime < fiveMinutesSeconds ||
        blockNumber - lastLotteryBlock < BLOCKS_IN_FIVE_MINUTES
      );
    }
    return false;
  }, [monthlyTimestamp, lastMonthlyBlock, blockNumber, monthlyBlockLoading, monthlyLoading]);

  const isWeeklyLotteryIncome = useMemo(() => {
    if (!weeklyLoading && !weeklyBlockLoading) {
      const lastLotteryBlock = +formatBigNumberToFixed(lastWeeklyBlock, 0, 0);
      const nextWeeklyTimestamp = +formatBigNumberToFixed(weeklyTimestamp, 0, 0);
      if (nextWeeklyTimestamp === 0) {
        return false;
      }
      return (
        nextWeeklyTimestamp - currentTime < fiveMinutesSeconds ||
        blockNumber - lastLotteryBlock < BLOCKS_IN_FIVE_MINUTES
      );
    }
    return false;
  }, [weeklyTimestamp, lastWeeklyBlock, blockNumber, weeklyLoading, weeklyBlockLoading]);

  return {
    isWeeklyLotteryIncome,
    isMonthlyLotteryIncome,
  };
};
