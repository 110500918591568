import styled from "styled-components";
import { space } from "styled-system";
import { InputProps, scales, ThemedProps } from "./types";

const getHeight = ({ scale = scales.MD }: ThemedProps) => {
  switch (scale) {
    case scales.SM:
      return "26px";
    case scales.MD:
      return "40px";
    case scales.LG:
      return "48px";
  }
};

const Input = styled.input<InputProps>`
  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor ? theme.colors[$backgroundColor] : theme.colors.monochrome25};
  color: ${({ theme }) => theme.colors.monochrome600};
  margin-top: 8px;
  display: block;
  font-size: 16px;
  height: ${getHeight};
  outline: 0;
  padding-left: 8px;
  width: 100%;
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  border: 1px solid ${({ theme }) => theme.colors.monochrome500};

  &::placeholder {
    color: ${({ theme }) => theme.colors.monochrome600};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.monochrome300};
    color: ${({ theme }) => theme.colors.monochrome400};
    cursor: not-allowed;

    &::placeholder {
      color: ${({ theme }) => theme.colors.monochrome400};
    }
  }

  &:focus:not(:disabled) {
  }
  ${space}

  ${({ theme }) => theme.mediaQueries.laptop} {
    font-size: 14px;
  }
`;

Input.defaultProps = {
  scale: scales.SM,
};

export default Input;
