import React from "react";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Components
import { Flex, Text } from "components";
// Types
import { NoDataComponentProps } from "./types";

const NoDataComponent: React.FC<NoDataComponentProps> = ({ text = "List is empty", height = 243 }) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <Flex width="100%" height={height} flexDirection="column" justifyContent="center" alignItems="center">
      <Text variant={isDesktop ? "body1" : "smallText"} my="16px" color="monochrome700">
        {t(text)}
      </Text>
    </Flex>
  );
};

export default NoDataComponent;
