import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { ROUTES } from "navigation/routes";

export type ModalState<T> = {
  modalName: null | keyof typeof ModalNames;
  rootId: (typeof ROUTES)[keyof typeof ROUTES] | "modal-story" | "modal";
  props?: T;
};

export type ShowModalProps<T> = {
  modalName: ModalState<T>["modalName"];
  rootId?: ModalState<T>["rootId"];
  props?: T;
};

export enum ModalNames {
  gameModal = "gameModal",
  chooseWalletModal = "chooseWalletModal",
  registrationModal = "registrationModal",
}

export interface ModalProps<T> extends ActionCreatorWithPayload<ShowModalProps<T>, string> {}
