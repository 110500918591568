import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "monochrome900", ...props }) => {
  return (
    <Svg viewBox="0 0 21 20" {...props} color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.24992 2.33319C3.86949 2.33319 3.53622 2.47785 3.25707 2.75701C2.97792 3.03616 2.83325 3.36943 2.83325 3.74986V16.2499C2.83325 16.6303 2.97792 16.9636 3.25707 17.2427C3.53622 17.5219 3.86949 17.6665 4.24992 17.6665H16.7499C17.1303 17.6665 17.4636 17.5219 17.7428 17.2427C18.0219 16.9636 18.1666 16.6303 18.1666 16.2499V12.3332H16.5833V16.0832H4.41659V3.91652H8.16658V2.33319H4.24992ZM18.1666 2.33319H11.1458V3.91652H15.4726L7.34893 12.0401L8.45686 13.1745L16.5833 5.04806V9.35402H18.1666V2.33319Z"
      />
    </Svg>
  );
};

export default Icon;
