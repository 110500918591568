import { DefaultTheme } from "styled-components";
import { SpaceProps } from "styled-system";

export const variants = {
  SQUARE: "square",
  CIRCLE: "circle",
} as const;

export type Variants = typeof variants[keyof typeof variants];

export interface ThemedProps extends AvatarBlockiesProps {
  theme: DefaultTheme;
}

export interface AvatarBlockiesProps extends SpaceProps {
  seed: string;
  circle?: boolean;
  scale?: number;
  size?: number;
  bgColor?: string;
  spotColor?: string;
  variant?: Variants;
}
