import Button from "components/button";
import styled from "styled-components";

export const ProfileButton = styled(Button)`
  border: none;
  display: flex;
  justify-content: center;
  height: 20px;
  width: 100%;
  background-color: transparent;
  padding: 0;
  margin-bottom: 0;

  :nth-last-of-type(1) {
    color: ${({ theme }) => theme.colors.error500};
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    justify-content: start;
    margin: 12px;
    width: auto;
  }
`;

export const Divider = styled.div`
  margin: 12px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.monochrome400};
`;
