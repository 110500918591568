import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "accent500", fill = "monochrome1000", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <g clipPath="url(#clip0_2918_3226)">
        <path
          d="M3.02198 20H16.9775C18.4405 20 19.6294 18.8111 19.6294 17.3481V4.13331C19.6294 2.67035 18.4405 1.48145 16.9775 1.48145H15.9257V0.740727C15.9257 0.333315 15.5923 0 15.1849 0C14.7775 0 14.4442 0.333315 14.4442 0.740727V1.48145H5.55533V0.740727C5.55533 0.333315 5.22195 0 4.81454 0C4.40713 0 4.07381 0.333315 4.07381 0.740727V1.48145H3.02198C1.55902 1.48145 0.370117 2.67035 0.370117 4.13331V17.3481C0.370117 18.8111 1.55902 20 3.02198 20V20ZM1.85163 4.13331C1.85163 3.48889 2.37755 2.96297 3.02198 2.96297H4.07381V3.7037C4.07381 4.11111 4.40713 4.44442 4.81454 4.44442C5.22195 4.44442 5.55527 4.11111 5.55527 3.7037V2.96297H14.4442V3.7037C14.4442 4.11111 14.7775 4.44442 15.1849 4.44442C15.5923 4.44442 15.9256 4.11111 15.9256 3.7037V2.96297H16.9775C17.6219 2.96297 18.1478 3.48889 18.1478 4.13331V6.2963H1.85163V4.13331ZM1.85163 7.77776H18.1479V17.3481C18.1479 17.9926 17.622 18.5185 16.9776 18.5185H3.02198C2.37755 18.5185 1.85163 17.9926 1.85163 17.3481V7.77776Z"
          fill={theme.colors[color]}
        />
        <path
          d="M6.66699 15.4V10H7.63449L9.72699 12.49L11.812 10H12.787V15.4H11.9695V11.0875L9.72699 13.7575L7.47699 11.0875V15.4H6.66699Z"
          fill={theme.colors[color]}
        />
      </g>
      <defs>
        <clipPath id="clip0_2918_3226">
          <rect width="20" height="20" fill={theme.colors[fill]} />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
