import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { HelmetProvider } from "react-helmet-async";
import { Web3ReactProvider } from "@web3-react/core";
import { BrowserRouter } from "react-router-dom";

// Hooks
import {
  usePollBlockNumber,
  useTransactionsUpdater,
  useMulticallUpdater,
  useWeb3AutoConnect,
  useGameMechanic,
  useLotteryDraw,
} from "hooks";
// Styles
import { GlobalStyle, StyledToastContainer } from "styles";
// Context
import { LanguageContextProvider, ThemeContextProvider, useThemeContext } from "context";
// Store
import store from "store/store";
// Components
import { ErrorBoundary, Loader, Modal, ErrorBoundaryFallback } from "components";
import Navigation from "navigation";
// Utils
import { getLibrary } from "utils/web3";

// TODO add the same table for results

const ThemedApp: React.FC = () => {
  const { theme } = useThemeContext();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <Suspense fallback={<Loader />}>
        <ErrorBoundary fallbackComponent={ErrorBoundaryFallback}>
          <LanguageContextProvider fallback={<Loader />}>
            <Provider store={store}>
              <Modal />
              <Navigation />
              <StyledToastContainer />
              <Updaters />
            </Provider>
          </LanguageContextProvider>
        </ErrorBoundary>
      </Suspense>
    </ThemeProvider>
  );
};

const Updaters: React.FC = () => {
  usePollBlockNumber();
  useMulticallUpdater();
  useGameMechanic();
  useTransactionsUpdater();
  useWeb3AutoConnect();
  useLotteryDraw();
  return null;
};

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <Web3ReactProvider getLibrary={getLibrary}>
          <ThemeContextProvider>
            <ThemedApp />
          </ThemeContextProvider>
        </Web3ReactProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
};

export default App;
