import { css } from "styled-components";
import { ThemedProps, variants } from "./types";

export const getCurrentProgressVariant = (progress: number) => {
  switch (true) {
    case progress <= 30:
      return variants.error;

    case progress <= 60:
      return variants.warning;

    default:
      return variants.success;
  }
};

export const getCurrentProgressStyles = ({ theme, progress = 0 }: ThemedProps) => {
  let variant = getCurrentProgressVariant(progress);

  return {
    error: css`
      background: ${theme.colors.error500};
      box-shadow: ${theme.shadows.errorProgressLine};
    `,
    warning: css`
      background: ${theme.colors.warning500};
      box-shadow: ${theme.shadows.warningProgressLine};
    `,
    success: css`
      background: ${theme.colors.success600};
      box-shadow: ${theme.shadows.successProgressLine};
    `,
  }[variant];
};
