import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "pieChartJackpotColor", ...props }) => {
  return (
    <Svg viewBox="0 0 86 84" {...props} color={color}>
      <g clipPath="url(#clip0_834_3275)">
        <path d="M78.6036 75.5941L84.6175 69.5803C85.0164 69.1797 85.1347 68.5771 84.9179 68.0551C84.7012 67.533 84.1923 67.1898 83.6259 67.1882H72.4174V61.5848C72.4174 60.0382 71.1631 58.7839 69.6149 58.7823H16.3819C14.8353 58.7823 13.581 60.0366 13.5793 61.5848V67.1882H2.37091C1.80449 67.1898 1.29554 67.5313 1.07883 68.0551C0.862112 68.5788 0.981962 69.1797 1.38091 69.5803L7.39475 75.5941L1.38091 81.6079C0.981962 82.0085 0.863754 82.6111 1.07883 83.1332C1.29554 83.6552 1.80449 83.9984 2.37091 84H27.5886C28.3619 84 28.9891 83.3729 28.9891 82.5996V75.5941H57.0093V82.5996C57.0093 83.3729 57.6365 84 58.4098 84H83.6275C84.1939 83.9984 84.7029 83.6569 84.9196 83.1332C85.1363 82.6111 85.0164 82.0085 84.6175 81.6079L78.6036 75.5941ZM26.1866 81.1975H5.75297L10.3664 76.5841C10.6291 76.3214 10.7768 75.9651 10.7768 75.5941C10.7768 75.2231 10.6291 74.8668 10.3664 74.6041L5.75297 69.9907H13.5793V72.7932C13.5793 74.3398 14.8337 75.5941 16.3819 75.5958H26.1882V81.1991L26.1866 81.1975ZM16.3802 72.7916V61.5832H69.6182V68.5821V68.5886V68.5952L69.6198 72.7916H16.3819H16.3802ZM59.8102 81.1975V75.5941H69.6165C71.1631 75.5941 72.4174 74.3398 72.4191 72.7916V69.9891H80.2454L75.632 74.6025C75.3693 74.8652 75.2216 75.2214 75.2216 75.5925C75.2216 75.9635 75.3693 76.3198 75.632 76.5825L80.2454 81.1959H59.8102V81.1975Z" />
        <path d="M6.46725 44.6679L5.27204 51.5371C5.1801 52.0625 5.39517 52.5927 5.82368 52.908C6.25382 53.2232 6.82352 53.2659 7.29635 53.0213L13.5794 49.7656L19.8625 53.0213C20.3354 53.2659 20.9067 53.2232 21.3352 52.908C21.7637 52.5927 21.9788 52.0625 21.8868 51.5371L20.6916 44.6679L25.7582 39.8C26.144 39.429 26.2852 38.8708 26.121 38.3602C25.9568 37.8512 25.5168 37.4785 24.9865 37.403L17.9679 36.3982L14.8321 30.1365C14.5694 29.6949 14.0933 29.4256 13.5794 29.4256C13.0656 29.4256 12.5894 29.6965 12.3268 30.1365L9.19097 36.3982L2.17236 37.403C1.64206 37.4785 1.20207 37.8512 1.03789 38.3602C0.873712 38.8691 1.0149 39.429 1.40072 39.8L6.46725 44.6679ZM10.3139 39.0661C10.7687 39.0005 11.1627 38.7181 11.368 38.3076L13.5794 33.8912L15.7909 38.3076C15.9961 38.7181 16.3902 39.0021 16.8449 39.0661L21.7457 39.7688L18.2125 43.164C17.8776 43.4874 17.7233 43.9553 17.8021 44.415L18.641 49.2337L14.223 46.9434C13.8191 46.7332 13.3381 46.7332 12.9342 46.9434L8.51619 49.2337L9.35514 44.415C9.43395 43.9553 9.28126 43.4874 8.9447 43.164L5.41159 39.7688L10.3123 39.0661H10.3139Z" />
        <path d="M59.8792 38.3602C59.715 38.8691 59.8562 39.429 60.242 39.8L65.3086 44.6679L64.1133 51.5371C64.0214 52.0625 64.2365 52.5927 64.665 52.908C65.0935 53.2232 65.6648 53.2659 66.1377 53.0213L72.4208 49.7656L78.7038 53.0213C79.1767 53.2659 79.748 53.2232 80.1765 52.908C80.605 52.5927 80.8201 52.0625 80.7282 51.5371L79.5329 44.6679L84.5995 39.8C84.9853 39.429 85.1265 38.8691 84.9623 38.3602C84.7981 37.8512 84.3581 37.4785 83.8278 37.403L76.8092 36.3982L73.6734 30.1365C73.4107 29.6949 72.9346 29.4256 72.4208 29.4256C71.9069 29.4256 71.4308 29.6965 71.1681 30.1365L68.0323 36.3982L61.0137 37.403C60.4834 37.4785 60.0434 37.8496 59.8792 38.3602ZM69.1553 39.0661C69.61 39.0005 70.0041 38.7181 70.2093 38.3076L72.4208 33.8912L74.6322 38.3076C74.8375 38.7181 75.2315 39.0021 75.6863 39.0661L80.587 39.7688L77.0539 43.164C76.7173 43.4874 76.5646 43.9553 76.6434 44.415L77.4824 49.2337L73.0643 46.9434C72.6605 46.7332 72.1794 46.7332 71.7755 46.9434L67.3575 49.2337L68.1965 44.415C68.2753 43.9553 68.1226 43.4874 67.786 43.164L64.2529 39.7688L69.1536 39.0661H69.1553Z" />
        <path d="M31.6749 24.2064L29.4996 36.1159C29.4043 36.6363 29.6112 37.1666 30.0331 37.4867C30.4551 37.8053 31.0215 37.8594 31.4976 37.6247L42.9999 31.949L54.5022 37.6247C54.9767 37.8594 55.5431 37.8053 55.9667 37.4867C56.3903 37.1682 56.5955 36.6379 56.5003 36.1159L54.3249 24.2064L63.5599 15.7562C63.9605 15.3884 64.1132 14.8236 63.9507 14.3048C63.7881 13.786 63.3416 13.4084 62.8031 13.3345L49.9643 11.5828L44.2395 0.691189C43.9735 0.261043 43.5039 0 42.9999 0C42.4959 0 42.0263 0.261043 41.7604 0.691189L36.0355 11.5811L23.1968 13.3329C22.6583 13.4068 22.2101 13.7844 22.0492 14.3032C21.8866 14.822 22.0393 15.3884 22.4399 15.7545L31.6749 24.2064ZM37.1273 14.2605C37.5755 14.1997 37.9679 13.9256 38.178 13.525L42.9983 4.354L47.8185 13.525C48.0287 13.9256 48.4211 14.1997 48.8693 14.2605L59.4604 15.7069L51.859 22.6631C51.5027 22.9882 51.3402 23.4742 51.4272 23.9486L53.2413 33.8781L43.6189 29.1285C43.2281 28.9347 42.7701 28.9347 42.3793 29.1285L32.7569 33.8781L34.571 23.9486C34.658 23.4742 34.4939 22.9882 34.1392 22.6631L26.5378 15.7069L37.1289 14.2605H37.1273Z" />
      </g>
      <defs>
        <clipPath id="clip0_834_3275">
          <rect width="84.0591" height="84" fill="white" transform="translate(0.970459)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
