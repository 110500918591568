import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "pieChartJackpotColor", ...props }) => {
  return (
    <Svg viewBox="0 0 85 84" {...props} color={color}>
      <path d="M81.3045 5.77138C77.9447 5.55319 69.5747 4.62957 64.5054 0.762846C63.193 -0.254282 61.3589 -0.254282 60.0465 0.762846C54.974 4.62957 46.6073 5.55319 43.2475 5.77138C41.3527 5.8895 39.8745 7.4562 39.8663 9.35429V20.6575C39.8663 26.1271 41.1492 31.5195 43.61 36.4033H7.66933C3.80588 36.4083 0.674111 39.5384 0.669189 43.4035V77.0015C0.674111 80.8649 3.80424 83.9967 7.66933 84.0016H66.4659C70.3293 83.9967 73.4611 80.8666 73.466 77.0015V46.3646C80.5859 39.7074 84.638 30.4056 84.6659 20.6575V9.35429C84.6593 7.46441 83.1927 5.90098 81.3061 5.77138H81.3045ZM70.6656 76.9999C70.6656 79.3196 68.7856 81.1996 66.4659 81.1996H7.66933C5.34962 81.1996 3.46957 79.3196 3.46957 76.9999V43.4018C3.46957 41.0821 5.34962 39.2021 7.66933 39.2021H45.1866C45.4031 39.5499 45.6066 39.9026 45.8346 40.2487C49.5438 45.7724 54.723 50.1493 60.788 52.8841C61.7297 53.309 62.8091 53.309 63.7508 52.8841C66.205 51.7718 68.5247 50.3839 70.6656 48.7467V76.9982V76.9999ZM81.8639 20.6575C81.8179 33.4733 74.2814 45.0801 62.5926 50.3363C62.3842 50.4299 62.1447 50.4299 61.9364 50.3363C56.3471 47.8165 51.5732 43.7824 48.1559 38.6902C44.5714 33.3601 42.6602 27.0818 42.6667 20.6575V9.35429C42.67 8.93596 42.9965 8.59145 43.4148 8.56684C47.9066 8.27647 56.2585 7.16747 61.733 2.98904C62.0463 2.7446 62.486 2.7446 62.7977 2.98904C68.2721 7.16419 76.624 8.27483 81.1174 8.56684C81.5358 8.59145 81.8622 8.93596 81.8655 9.35429V20.6575H81.8639Z" />
      <path d="M76.6764 10.9243C72.1223 10.3714 67.7044 8.99995 63.6358 6.87711C62.7861 6.39643 61.746 6.39643 60.8962 6.87711C56.8277 8.99995 52.4097 10.3714 47.854 10.9243C46.4841 11.1277 45.4686 12.3023 45.4654 13.6869V20.6559C45.4588 26.5224 47.2043 32.2561 50.4755 37.1252C53.1463 41.0903 56.7194 44.3665 60.8994 46.6862C61.7476 47.1504 62.7746 47.1504 63.6244 46.6862C73.1214 41.4627 79.0338 31.4949 79.0634 20.6559V13.6869C79.0601 12.3023 78.0446 11.1277 76.6748 10.9243H76.6764ZM76.2646 20.6559C76.2351 30.4794 70.8755 39.5121 62.266 44.2418H62.2512C58.4583 42.1354 55.2183 39.1611 52.7969 35.5618C49.8374 31.1553 48.2592 25.9647 48.2674 20.6559L48.2526 13.6902C53.1529 13.1029 57.9038 11.6182 62.2676 9.31165C66.6265 11.6166 71.3709 13.0996 76.2663 13.6886V20.6576L76.2646 20.6559Z" />
      <path d="M11.8692 54.6017H17.4683C19.788 54.6017 21.6681 52.7217 21.6681 50.402V47.6016C21.6681 45.2819 19.788 43.4019 17.4683 43.4019H11.8692C9.54948 43.4019 7.66943 45.2819 7.66943 47.6016V50.402C7.66943 52.7217 9.54948 54.6017 11.8692 54.6017ZM10.4698 47.6016C10.4698 46.8289 11.0965 46.2022 11.8692 46.2022H17.4683C18.241 46.2022 18.8677 46.8289 18.8677 47.6016V50.402C18.8677 51.1747 18.241 51.8014 17.4683 51.8014H11.8692C11.0965 51.8014 10.4698 51.1747 10.4698 50.402V47.6016Z" />
      <path d="M9.0688 61.6018H14.6679C15.4406 61.6018 16.0673 60.9752 16.0673 60.2025C16.0673 59.4298 15.4406 58.8031 14.6679 58.8031H9.0688C8.29612 58.8031 7.66943 59.4298 7.66943 60.2025C7.66943 60.9752 8.29612 61.6018 9.0688 61.6018Z" />
      <path d="M25.8676 58.8015H20.2685C19.4958 58.8015 18.8691 59.4281 18.8691 60.2008C18.8691 60.9735 19.4958 61.6002 20.2685 61.6002H25.8676C26.6403 61.6002 27.267 60.9735 27.267 60.2008C27.267 59.4281 26.6403 58.8015 25.8676 58.8015Z" />
      <path d="M37.0676 58.8015H31.4685C30.6958 58.8015 30.0691 59.4281 30.0691 60.2008C30.0691 60.9735 30.6958 61.6002 31.4685 61.6002H37.0676C37.8403 61.6002 38.467 60.9735 38.467 60.2008C38.467 59.4281 37.8403 58.8015 37.0676 58.8015Z" />
      <path d="M48.2658 58.8015H42.6667C41.894 58.8015 41.2673 59.4281 41.2673 60.2008C41.2673 60.9735 41.894 61.6002 42.6667 61.6002H48.2658C49.0385 61.6002 49.6652 60.9735 49.6652 60.2008C49.6652 59.4281 49.0385 58.8015 48.2658 58.8015Z" />
      <path d="M60.8652 60.2025C60.8652 59.4298 60.2385 58.8031 59.4658 58.8031H53.8667C53.094 58.8031 52.4673 59.4298 52.4673 60.2025C52.4673 60.9752 53.094 61.6018 53.8667 61.6018H59.4658C60.2385 61.6018 60.8652 60.9752 60.8652 60.2025Z" />
      <path d="M18.8677 67.201H14.6679C13.8952 67.201 13.2686 67.8277 13.2686 68.6004C13.2686 69.373 13.8952 69.9997 14.6679 69.9997H18.8677C19.6404 69.9997 20.267 69.373 20.267 68.6004C20.267 67.8277 19.6404 67.201 18.8677 67.201Z" />
      <path d="M18.8677 72.8018H9.0688C8.29612 72.8018 7.66943 73.4284 7.66943 74.2011C7.66943 74.9738 8.29612 75.6005 9.0688 75.6005H18.8677C19.6404 75.6005 20.2671 74.9738 20.2671 74.2011C20.2671 73.4284 19.6404 72.8018 18.8677 72.8018Z" />
      <path d="M58.0663 19.6043V21.0037C56.5192 21.0037 55.2659 22.257 55.2659 23.804V32.2036C55.2659 33.7506 56.5192 35.0039 58.0663 35.0039H66.4658C68.0128 35.0039 69.2661 33.7506 69.2661 32.2036V23.804C69.2661 22.257 68.0128 21.0037 66.4658 21.0037V19.6043C66.4658 17.2846 64.5857 15.4045 62.266 15.4045C59.9463 15.4045 58.0663 17.2846 58.0663 19.6043ZM60.8666 19.6043C60.8666 18.8316 61.4933 18.2049 62.266 18.2049C63.0387 18.2049 63.6654 18.8316 63.6654 19.6043V21.0037H60.865V19.6043H60.8666ZM65.0664 23.804H66.4658V32.2036H58.0663V23.804H65.0664Z" />
      <path d="M62.2661 29.4032C63.0389 29.4032 63.6654 28.7767 63.6654 28.0038C63.6654 27.231 63.0389 26.6044 62.2661 26.6044C61.4932 26.6044 60.8667 27.231 60.8667 28.0038C60.8667 28.7767 61.4932 29.4032 62.2661 29.4032Z" />
      <path d="M63.6654 74.2011H56.6652C55.8926 74.2011 55.2659 74.8278 55.2659 75.6005C55.2659 76.3732 55.8926 76.9999 56.6652 76.9999H65.0647C65.8374 76.9999 66.4641 76.3732 66.4641 75.6005V68.6004C66.4641 67.8277 65.8374 67.201 65.0647 67.201C64.2921 67.201 63.6654 67.8277 63.6654 68.6004V74.1995V74.2011Z" />
    </Svg>
  );
};

export default Icon;
