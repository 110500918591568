import useSWR, { useSWRConfig } from "swr";
import useSWRImmutable from "swr/immutable";

import { useActiveWeb3React } from "hooks";
import { getSimpleRpcProvider } from "utils/web3";
// In average one block in BSC network takes a 3 sec
// We use 6 sec to call bch requests ones in a two blocks
// Sometimes it ones in a three blocks
// This will affect on game mechanics
export const REFRESH_BLOCK_INTERVAL = 6000;

export const usePollBlockNumber = () => {
  const { cache, mutate } = useSWRConfig();
  const { chainId } = useActiveWeb3React();

  useSWR(
    chainId ? `${chainId}/blockNumber` : null,
    async () => {
      const simpleRpcProvider = getSimpleRpcProvider(chainId);

      const blockNumber = await simpleRpcProvider.getBlockNumber();

      if (!cache.get(`${chainId}/initialBlockNumber`)) {
        mutate(`${chainId}/initialBlockNumber`, blockNumber);
      }

      return blockNumber;
    },
    {
      refreshInterval: REFRESH_BLOCK_INTERVAL,
      revalidateOnFocus: false,
    },
  );
};

export const useCurrentBlock = () => {
  const { chainId } = useActiveWeb3React();

  const { data: currentBlock = 0 } = useSWRImmutable<number>(`${chainId}/blockNumber`);
  return currentBlock;
};

export const useInitialBlock = () => {
  const { chainId } = useActiveWeb3React();

  const { data: initialBlock = 0 } = useSWRImmutable<number>(`${chainId}/initialBlockNumber`);
  return initialBlock;
};

export const useGetBlockNumber = () => {
  const { chainId } = useActiveWeb3React();

  const { data: initialBlock = 0 } = useSWR(
    chainId ? `${chainId}/getBlockNumber` : null,
    async () => {
      const simpleRpcProvider = getSimpleRpcProvider(chainId);

      const blockNumber = await simpleRpcProvider.getBlockNumber();

      return blockNumber;
    },
    {
      revalidateOnFocus: false,
    },
  );
  return initialBlock;
};
