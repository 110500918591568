import styled from "styled-components";
import { Box, Flex } from "components";
import { ProgressLineProps } from "./types";
import { getCurrentProgressStyles } from "./theme";

export const StyledProgressLine = styled(Box)`
  width: 56px;
  height: 8px;
  position: relative;
  border-radius: ${({ theme }) => theme.radii.extraLarge};
  background: ${({ theme }) => theme.colors.monochrome300};

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 308px;
  }
`;

export const StyledCurrentProgress = styled(Flex)<ProgressLineProps>`
  width: ${({ progress }) => `${progress}%`};
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.radii.extraLarge};
  transition: all 0.5s ease-in-out;

  ${({ theme, progress }) => progress && getCurrentProgressStyles({ theme, progress })};
`;
