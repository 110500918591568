import { Box, Flex } from "components/layout";
import styled from "styled-components";
import { space, layout } from "styled-system";
import { StyledTabPanelProps, StyledTabPanelButtonProps } from "./types";

const getHovered = ({ isActive, isShowLineOnHover, theme }: StyledTabPanelButtonProps) => {
  if (!isActive && isShowLineOnHover) {
    return `
      :hover {
        border-bottom-color: ${theme.colors.monochrome700};
      }
    `;
  }
};

export const StyledTabPanelWrapper = styled(Box)<{ isShowTopDivider: boolean }>`
  overflow: auto;
  border-top: ${({ theme, isShowTopDivider }) =>
    isShowTopDivider ? `1px solid ${theme.colors.monochrome300}` : "none"};

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledTabPanel = styled(Flex)<StyledTabPanelProps>`
  width: max-content;
  justify-content: space-around;
  align-items: center;
  position: relative;

  ${space}
  ${layout}
`;

export const StyledTabPanelButton = styled(Box)<StyledTabPanelButtonProps>`
  border: none;
  background: none;
  cursor: pointer;
  border-bottom: ${({ theme, isActive, isBorderAroundTab }) =>
    !isBorderAroundTab ? `2px solid ${isActive ? theme.colors.monochrome1000 : "transparent"}` : "auto"};
  border: ${({ theme, isActive, isBorderAroundTab }) =>
    isBorderAroundTab ? `1px solid ${isActive ? theme.colors.monochrome1000 : "transparent"}` : "auto"};
  border-radius: ${({ theme, isBorderAroundTab }) => (isBorderAroundTab ? theme.radii.semiMedium : "none")};
  transition: all 0.2s ease-in-out;
  z-index: 1;
  padding: 0;

  ${getHovered};

  ${({ theme }) => theme.mediaQueries.laptop} {
    border-bottom-width: ${({ isBorderAroundTab }) => (!isBorderAroundTab ? "4px" : "auto")};
  }
`;
