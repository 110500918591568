import { toast } from "react-toastify";
import { toastOptionsError, toastOptionsSuccess } from "components";

export const handleCopyToClipboard = async (copyValue: string, success: string, failure: string) => {
  try {
    await navigator.clipboard.writeText(copyValue);
    toast.success(success, toastOptionsSuccess);
  } catch (error) {
    toast.error(failure, toastOptionsError);
  }
};
