import { Zero } from "@ethersproject/constants";
import { BigNumber } from "@ethersproject/bignumber";
import { useEffect } from "react";
//Hooks
import { useGetBalanceByAddress, useSingleCallResult } from "hooks";
import { useActiveWeb3React, useMonthlyLotteryContract, useSWRContract } from "hooks";
// Store
import { useAppDispatch, useAppSelector } from "store/store";
import { checkedNominalTimestamp } from "store/nominals";
// Constants
import { blocksPerFetch, SLOW_INTERVAL } from "utils/web3";
import { contractsAddresses } from "configs";
import { ListenerOptions } from "./use-multicall/types";

export const useMonthlyLotteryStatistics = (configs?: {
  listenerOptionsUsers?: ListenerOptions;
  refreshIntervalBalance?: number;
}) => {
  const refreshIntervalBalance = configs?.refreshIntervalBalance ?? SLOW_INTERVAL;
  const listenerOptionsUsers = configs?.listenerOptionsUsers ?? { blocksPerFetch };

  const { chainId } = useActiveWeb3React();
  const monthlyLotteryContract = useMonthlyLotteryContract(chainId);

  const { monthly: isActiveNominal } = useAppSelector(({ nominals }) => nominals.timestamps);
  const dispatch = useAppDispatch();

  // Timestamp in seconds for the next lottery to draw
  // Should revalidate on focus
  const { data = Zero, isValidating, mutate } = useSWRContract([monthlyLotteryContract, "nextLotteryTimestamp"]);

  useEffect(() => {
    if (isActiveNominal) {
      dispatch(checkedNominalTimestamp({ nominal: "monthly", isChecked: false }));
      mutate();
    }
  }, [isActiveNominal]);

  // Balance of the contract
  const { balance: monthlyLotteryBalance } = useGetBalanceByAddress(
    contractsAddresses.monthlyLotteryContract[chainId],
    {
      refreshInterval: refreshIntervalBalance,
      revalidateOnFocus: false,
    },
  );

  // Users that participate at the current draw
  const usersCount: BigNumber =
    useSingleCallResult(monthlyLotteryContract, "uniqueUsersCount", undefined, listenerOptionsUsers).result?.[0] ||
    Zero;

  return {
    monthlyTimestamp: data,
    loading: isValidating,
    monthlyLotteryBalance,
    usersCount,
    refreshTimestamp: mutate,
  };
};
