export const ROUTES = {
  landing: "/",
  main: "main",
  registration: "registration",
  gameTable: "game-table",
  profile: "profile",
  lotteryDraw: "lottery-draw",
};

export const ROUTES_PARAMS = {
  nominal: "nominal",
};

export const ROUTE_SEARCH_PARAMS = {
  referrer: "referrer",
};
