import { useEffect, useState, useRef } from "react";
import { useWeb3React } from "@web3-react/core";

import { getSimpleRpcProvider } from "utils/web3";

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID as string;

export const useActiveWeb3React = () => {
  const { library, chainId, ...web3React } = useWeb3React();
  const refEth = useRef(library);
  const [provider, setProvider] = useState(library || getSimpleRpcProvider(+CHAIN_ID));

  useEffect(() => {
    if (library !== refEth.current) {
      setProvider(library || getSimpleRpcProvider(+CHAIN_ID));
      refEth.current = library;
    }
  }, [library]);

  return { library: provider, chainId: chainId ?? parseInt(CHAIN_ID, 10), ...web3React };
};
