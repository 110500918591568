import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg viewBox="0 0 40 40" {...props}>
      <path
        d="M40 18.6715C39.6552 13.4988 37.3132 8.66186 33.4676 5.18053C29.622 1.69919 24.5734 -0.154558 19.3862 0.0101011C14.1989 0.17476 9.27829 2.34496 5.66176 6.06316C2.04523 9.78137 0.0153513 14.757 8.67157e-05 19.9412C-0.0151779 25.1253 1.98536 30.1128 5.57993 33.8522C9.17451 37.5916 14.0822 39.7907 19.2685 39.9858C24.4547 40.181 29.5141 38.3569 33.3801 34.8982C37.2461 31.4395 39.6166 26.6165 39.9918 21.4459L30.0425 21.4459C29.8543 24.0393 28.6612 25.7378 26.7222 27.4726C24.7831 29.2074 22.2454 30.1223 19.6441 30.0244C17.0428 29.9265 14.5812 28.8235 12.7783 26.9479C10.9753 25.0723 9.97189 22.5707 9.97954 19.9705C9.9872 17.3703 11.0053 14.8746 12.8193 13.0096C14.6333 11.1447 17.1013 10.0561 19.7031 9.97355C22.3049 9.89096 24.8372 10.8208 26.766 12.5669C28.6949 14.3131 29.8696 16.077 30.0425 18.6715H40Z"
        fill="#1552EF"
      />
    </Svg>
  );
};

export default Icon;
