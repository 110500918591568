import React from "react";
import { useThemeContext } from "context";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "monochrome1000", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 24 24" {...props} stroke={theme.colors[color]}>
      <path d="M5 12H19" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  );
};

export default Icon;
