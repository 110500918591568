import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "monochrome1000", ...props }) => {
  return (
    <Svg viewBox="0 0 24 24" {...props} color={color}>
      <g clipPath="url(#clip0_834_3304)">
        <path d="M12 12.5C15.315 12.5 18 9.815 18 6.5C18 3.185 15.315 0.5 12 0.5C8.685 0.5 6 3.185 6 6.5C6 9.815 8.685 12.5 12 12.5ZM12 15.5C7.995 15.5 0 17.51 0 21.5V23C0 23.825 0.675 24.5 1.5 24.5H22.5C23.325 24.5 24 23.825 24 23V21.5C24 17.51 16.005 15.5 12 15.5Z" />
      </g>
      <defs>
        <clipPath id="clip0_834_3304">
          <rect width="24" height="24" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
