import styled from "styled-components";
import { space, typography, layout, opacity } from "styled-system";
import { getEllipsis, getFontSettings } from "./theme";
import { TextProps, variants } from "./types";

export const Text = styled.p<TextProps>`
  ${getFontSettings}

  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.monochrome1000)};

  ${getEllipsis}
  ${space}
  ${typography}
  ${layout}
  ${opacity}
`;

Text.defaultProps = {
  variant: variants.body1,
};

export default Text;
