import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "navigation/routes";

import { Heading, Text, Button } from "components";

import { ErrorBoundaryFallbackProps } from "./types";
import { StyledFlexWrapper } from "./styled";

const ErrorBoundaryFallback: React.FC<ErrorBoundaryFallbackProps> = ({ error, resetError }) => {
  const navigate = useNavigate();

  const onPressHandler = () => {
    resetError();
    navigate(ROUTES.landing);
  };

  return (
    <StyledFlexWrapper>
      <Heading as="h1">Something went wrong!</Heading>
      <Text mt="8px">{error?.toString()}</Text>
      <Heading mt="8px" as="h2" scale="h2">
        Please, go to home page
      </Heading>
      <Button mt="8px" scale="lg" onClick={onPressHandler}>
        Go to home page
      </Button>
    </StyledFlexWrapper>
  );
};

export default ErrorBoundaryFallback;
