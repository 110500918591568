import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "monochrome1000", ...props }) => {
  return (
    <Svg viewBox="0 0 20 20" {...props} color={color}>
      <g clipPath="url(#clip0_1700_2256)">
        <path d="M8 17.9999L9.4 16.5999L3.8 10.9999L20 10.9999V8.99991L3.8 8.99991L9.4 3.39991L8 1.99991L0 9.99991L8 17.9999Z" />
      </g>
      <defs>
        <clipPath id="clip0_1700_2256">
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
