import React from "react";
import CountUp from "react-countup";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints, useTooltip } from "hooks";
// Components
import { Flex, Box, Text, LotteryCountDown, Skeleton, Heading, Container, Button } from "components";
import { UserIcon } from "components/svg";
// Types
import { LotteryTimerInfoProps } from "./types";
// Utils
import { formatBigNumberToFixed } from "utils/web3";
// Styles
import { StyledLotteryTimerInfoWrapper, StyledLotteryTimerInfo, StyledLotteryCountDownWrapper } from "./styled";

const LotteryTimerInfo: React.FC<LotteryTimerInfoProps> = ({
  sectionTitle,
  buttonLabel,
  onButtonClick,
  additionalInfo,
  loading,
  participantsCount,
  refreshTimestamp,
  lotteryBalance,
  lotteryTimestamp,
  isHideBgImage = false,
  isHideTimerLabels = false,
  isAllInfoInColumn = false,
  isShowCounterTopBorder = false,
  ...props
}) => {
  const { t } = useTranslation();
  const { isDesktop, isLaptopL, isLaptop } = useMatchBreakpoints();

  const { targetRef: targetParticipantsCount, tooltip: tooltipParticipantsCount } = useTooltip(
    +formatBigNumberToFixed(participantsCount, 0, 0),
    {
      placement: "top",
      isEllipsis: true,
    },
  );

  const { targetRef: targetMonthlyLotteryBalance, tooltip: tooltipLotteryBalance } = useTooltip(
    +formatBigNumberToFixed(lotteryBalance, 3) + " BNB",
    {
      placement: "top",
      isEllipsis: true,
    },
  );

  return (
    <StyledLotteryTimerInfoWrapper isHideBgImage={isHideBgImage}>
      <Container {...props}>
        {sectionTitle && (
          <Heading scale="h2" mb="40px">
            {t(sectionTitle)}
          </Heading>
        )}
        <StyledLotteryTimerInfo>
          <Box width={{ _: "100%", laptop: "calc(50% - 8px)" }}>
            <Flex
              flexDirection={isAllInfoInColumn ? "column" : "row"}
              justifyContent={{ _: "space-between", laptop: "flex-start" }}
              alignItems={isAllInfoInColumn ? "flex-start" : "flex-end"}
              mb={{ _: "24px", laptop: "32px" }}
            >
              <Heading scale={!isDesktop ? "h6" : "h5"} color="monochrome700">
                {t("Number of participants")}
              </Heading>
              <Flex mx={{ _: "0", laptop: "8px" }}>
                <UserIcon width="24px" mx="8px" />

                <Heading scale="h3" maxWidth={{ _: "120px", laptop: "200px" }} ref={targetParticipantsCount} ellipsis>
                  <CountUp
                    end={+formatBigNumberToFixed(participantsCount, 0, 0)}
                    separator=","
                    preserveValue
                    scrollSpyOnce
                  />
                </Heading>
                {tooltipParticipantsCount}
              </Flex>
            </Flex>
            <Flex
              flexDirection={isAllInfoInColumn ? "column" : "row"}
              justifyContent={{ _: "space-between", laptop: "flex-start" }}
              alignItems={isAllInfoInColumn ? "flex-start" : "flex-end"}
              mb={{ _: "24px", laptop: "0" }}
            >
              <Heading scale={!isDesktop ? "h6" : "h5"} color="monochrome700">
                {t("Bank")}
              </Heading>

              <Heading
                scale="h3"
                maxWidth={{ _: "220px", laptop: "450px" }}
                mx={{ _: "0", laptop: "16px" }}
                ref={targetMonthlyLotteryBalance}
                ellipsis
              >
                <CountUp
                  end={+formatBigNumberToFixed(lotteryBalance, 3)}
                  decimals={3}
                  separator=","
                  suffix=" BNB"
                  preserveValue
                  scrollSpyOnce
                />
              </Heading>
              {tooltipLotteryBalance}
            </Flex>
          </Box>
          <Flex width={{ _: "100%", laptop: "calc(50% - 8px)" }} justifyContent={{ _: "center", laptop: "flex-end" }}>
            <StyledLotteryCountDownWrapper isShowCounterTopBorder={isShowCounterTopBorder}>
              {loading ? (
                <Skeleton
                  width={{ _: "100%", laptop: "420px" }}
                  height={isHideTimerLabels ? (isDesktop ? "60px" : "40px") : isDesktop ? "84px" : "62px"}
                />
              ) : (
                <Box width="fit-content" mx={{ _: "auto", laptop: "0" }}>
                  <LotteryCountDown
                    timestamp={+formatBigNumberToFixed(lotteryTimestamp, 0, 0)}
                    numberWidth={isLaptopL ? 48 : isLaptop ? 40 : 30}
                    numberHeight={isLaptopL ? 48 : isLaptop ? 40 : 30}
                    onReachZeroCallback={refreshTimestamp}
                    headingScale={isDesktop ? "h1" : undefined}
                    textVariant={isDesktop ? undefined : "decor2Mobile"}
                    isHideTimerLabels={isHideTimerLabels}
                    isText={!isDesktop}
                  />
                </Box>
              )}

              {additionalInfo && (
                <Text variant="body1" textAlign="center" mt="16px">
                  {t(additionalInfo)}
                </Text>
              )}

              {buttonLabel && onButtonClick && (
                <Flex justifyContent="center" mt={{ _: "12px", laptop: "16px" }}>
                  <Button scale="lg" onClick={onButtonClick}>
                    {t(buttonLabel)}
                  </Button>
                </Flex>
              )}
            </StyledLotteryCountDownWrapper>
          </Flex>
        </StyledLotteryTimerInfo>
      </Container>
    </StyledLotteryTimerInfoWrapper>
  );
};

export default LotteryTimerInfo;
