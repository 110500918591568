import useSWR from "swr";
import { toast } from "react-toastify";
// Context
import { useTranslation } from "context";
// Hooks
import { useActiveWeb3React, useCurrentBlock, useSlotsContractFromCustomRPC } from "hooks";
// Components
import { ToastDescriptionLotteryDraw, toastOptionsSuccess } from "components";
// Types
import { UserProfileTabs } from "pages/profile/types";
// Utils
import { formatBigNumberToFixed } from "utils/web3";
// TODO need to add store to store nominals that passed
// And in useGameTableModalInfo subscribe for them and hide modal and mutate on response
export const useGameMechanic = () => {
  const { account, chainId } = useActiveWeb3React();

  const slotsContract = useSlotsContractFromCustomRPC(chainId);
  const { t } = useTranslation();

  const blockNumber = useCurrentBlock();

  useSWR(
    blockNumber && chainId ? `${chainId}/getPlayerGames${blockNumber}` : null,
    async () => {
      return getPlayerGames();
    },
    { revalidateOnFocus: false },
  );

  const getPlayerGames = async () => {
    const eventFilter = slotsContract!.filters.GameFinished();
    // blockNumber updates ones in two blocks, sometimes ones in a three blocks
    // So we add additional constant for extra check in case we lose blocks
    const events = await slotsContract!.queryFilter(eventFilter, blockNumber - 2, blockNumber);

    events.forEach(game => {
      const gameInfo = game?.args;
      const reward = formatBigNumberToFixed(gameInfo?.["reward"], 9);
      const losers = gameInfo?.["losers"];
      const winners = gameInfo?.["winners"];
      const bet = gameInfo?.["bet"];

      if (winners.some(winner => winner === account)) {
        toast.success(
          <ToastDescriptionLotteryDraw
            description={t("You win %message% BNB in table with %nominal%.", {
              message: reward,
              nominal: formatBigNumberToFixed(bet, 3),
            })}
          />,
          toastOptionsSuccess,
        );
      }

      if (losers.some(loser => loser === account)) {
        toast.success(
          <ToastDescriptionLotteryDraw
            description={t("You receive 1 ticket to weekly Lottery", {
              message: formatBigNumberToFixed(bet, 3),
            })}
            profilePage={UserProfileTabs.Tickets}
          />,
          toastOptionsSuccess,
        );
      }
    });
  };
};
