import styled from "styled-components";

import { Text, Flex, Svg } from "components";

import { InputIconProps, StyledInputGroupProps, InputErrorProps } from "./types";
import { Scales, scales as inputScales } from "components/input/types";

const getPadding = (scale: Scales) => {
  switch (scale) {
    case inputScales.SM:
      return 8;
    case inputScales.MD:
      return 10;
    case inputScales.LG:
      return 12;
  }
};

export const InputLabel = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.monochrome1000};
`;

export const InputIcon = styled.div<InputIconProps>`
  align-items: center;
  justify-content: center;
  display: flex;
  padding: ${({ scale }) => getPadding(scale)}px;
`;

export const InputError = styled(Text)<InputErrorProps>`
  white-space: pre-wrap;
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.monochrome600};
`;

export const InputWrapper = styled(Flex)<StyledInputGroupProps>`
  margin-top: 16px;
  input {
    padding-left: ${({ hasStartIcon }) => hasStartIcon && 0};
    padding-right: ${({ hasEndIcon }) => hasEndIcon && 0};
    background-color: ${({ theme }) => theme.colors.monochrome300};
  }

  ${Svg} {
    fill: ${({ theme }) => theme.colors.monochrome400};
  }

  ${({ disabled, theme }) =>
    disabled &&
    `
    background-color: ${theme.colors.monochrome300};
    color: ${theme.colors.monochrome400};
    cursor: not-allowed;
    ${InputLabel} {
      color: ${theme.colors.monochrome400};
    }
    `}

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-top: 24px;
  }
`;
