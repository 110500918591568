import styled from "styled-components";
import { space, typography, layout, opacity, border, shadow, variant } from "styled-system";
// Components
import { Svg } from "components/svg";
import { getFontSettings } from "components/text/theme";
// Constants
import { scaleVariants, variantStyles } from "./theme";
// Types
import { HSL } from "theme/types";
import { ButtonProps, scalesToTextVariant } from "./types";

const StyledButton = styled.button<ButtonProps>`
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.monochrome1000)};

  position: relative;
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  justify-content: center;
  line-height: 1;
  outline: 0;
  font-size: 16px;
  border: 2px solid;
  transition: all 0.2s ease-in-out;
  width: fit-content;

  ${variant({
    prop: "scale",
    variants: scaleVariants,
  })}

  &:disabled {
    background-color: ${({ theme }) => theme.colors.monochrome400};
    border-color: ${({ theme }) => theme.colors.monochrome400};
    cursor: not-allowed;
  }

  ${Svg} {
    transition: all 0.2s ease-in-out;
  }

  ${({ theme, variant, accentColor, hsl }) => variantStyles(theme, variant, accentColor, hsl as keyof HSL)};

  ${({ theme, scale }) => scale && getFontSettings({ theme, variant: scalesToTextVariant[scale] })};

  ${space}
  ${typography}
  ${layout}
  ${opacity}
  ${border}
  ${shadow}
`;

export default StyledButton;
