import React from "react";
import { Route, Routes } from "react-router-dom";
// Components
// import { NotFoundPage } from "components";
// import { RequireAuth, WrapperLayout } from "./components";
// Configs
// import { ROUTES, ROUTES_PARAMS } from "./routes";
// Pages
// import { MainPage, LandingPage, RegistrationPage, TablePage, ProfilePage, LotteryPage } from "pages";
// Outlets
// import { BlureOutlet } from "components/outlets";

// Components
import { WrapperLayout } from "./components";
// Pages
import { LandingPage } from "pages";
// Outlets
import { BlureOutlet } from "components/outlets";

const Navigation: React.FC = () => {
  return (
    <Routes>
      <Route path="*" element={<BlureOutlet />}>
        <Route path="*" element={<WrapperLayout />}>
          {/* Landing routes */}
          <Route path="*" element={<LandingPage />} />
        </Route>
      </Route>
      {/* <Route path={ROUTES.landing} element={<WrapperLayout />}> */}
      {/* Landing routes */}
      {/* <Route index element={<LandingPage />} /> */}

      {/* Main page routes */}
      {/* <Route path={ROUTES.main}>
            <Route index element={<MainPage />} />
          </Route> */}
      {/* </Route> */}

      {/* Registration page routes */}
      {/* <Route path={ROUTES.registration} element={<WrapperLayout />}>
          <Route index element={<RegistrationPage />} />
        </Route> */}

      {/* Table page routes */}
      {/* <Route
          path={ROUTES.gameTable}
          element={
            <RequireAuth>
              <WrapperLayout />
            </RequireAuth>
          }
        >
          <Route index element={<TablePage />} />
          <Route path={`:${ROUTES_PARAMS.nominal}`} element={<TablePage />} />
        </Route> */}

      {/* User profile page routes */}
      {/* <Route
          path={ROUTES.profile}
          element={
            <RequireAuth>
              <WrapperLayout />
            </RequireAuth>
          }
        >
          <Route index element={<ProfilePage />} />
        </Route> */}

      {/* User lottery draw page */}
      {/* <Route
          path={ROUTES.lotteryDraw}
          element={
            <RequireAuth>
              <WrapperLayout />
            </RequireAuth>
          }
        >
          <Route index element={<LotteryPage />} />
          <Route path={`:${ROUTES_PARAMS.nominal}`} element={<LotteryPage />} />
        </Route> */}

      {/* <Route path="*" element={<NotFoundPage />} /> */}
    </Routes>
  );
};

export default Navigation;
