import styled from "styled-components";
import { motion } from "framer-motion";
import { Flex } from "components/layout";

export const StyledExpansionPanel = styled(Flex)`
  width: 100%;
  flex-direction: column;
`;

export const StyledExpansionPanelLabelWrapper = styled(motion.header)<{ isPanelOpen: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ isPanelOpen }) => (isPanelOpen ? "16px" : "0")};
  cursor: pointer;
`;

export const StyledExpansionContentWrapper = styled(motion.div)`
  transform-origin: top center;
`;
