import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "pieChartJackpotColor", ...props }) => {
  return (
    <Svg viewBox="0 0 84 85" {...props} color={color}>
      <g clipPath="url(#clip0_736_2637)">
        <path d="M79.8 0.0634766H4.2C1.8818 0.0651172 0.00164062 1.94527 0 4.26348V79.8635C0.00164062 82.1817 1.8818 84.0602 4.2 84.0635H79.8C82.1182 84.0602 83.9967 82.1817 84 79.8635V4.26348C83.9967 1.94527 82.1182 0.0651172 79.8 0.0634766ZM81.1995 79.8635C81.1995 80.6362 80.5727 81.2629 79.8 81.2629H4.2C3.42727 81.2629 2.80055 80.6362 2.80055 79.8635V4.26348C2.80055 3.49074 3.42727 2.86402 4.2 2.86402H79.8C80.5727 2.86402 81.1995 3.49074 81.1995 4.26348V79.8635Z" />
        <path d="M56.8492 39.0938C54.6606 36.9101 51.1185 36.9101 48.9299 39.0938C48.3835 39.6401 48.3835 40.5277 48.9299 41.074C49.4762 41.6204 50.3621 41.6204 50.9101 41.074C52.0044 39.983 53.7763 39.983 54.8706 41.074L67.7396 53.9431C68.8306 55.0374 68.8306 56.8076 67.7396 57.9019L57.8401 67.8015C56.7458 68.8925 54.9739 68.8925 53.8796 67.8015L41.0106 54.9324C39.9196 53.8381 39.9196 52.0679 41.0106 50.9736C41.5569 50.4272 41.5569 49.5413 41.0106 48.9933C40.4642 48.4454 39.5783 48.447 39.0303 48.9933C36.8467 51.1819 36.8467 54.724 39.0303 56.9126L51.8994 69.7817C54.088 71.9654 57.6301 71.9654 59.8187 69.7817L69.7182 59.8822C71.9019 57.6936 71.9019 54.1515 69.7182 51.9629L56.8492 39.0938Z" />
        <path d="M34.4429 42.6918C33.9606 42.5621 33.4438 42.7 33.0911 43.0543C31.9968 44.1454 30.2249 44.1454 29.1306 43.0543L16.2615 30.1853C15.1705 29.091 15.1705 27.3207 16.2615 26.2264L26.1611 16.3269C27.2554 15.2359 29.0272 15.2359 30.1215 16.3269L42.9906 29.196C44.0816 30.2903 44.0816 32.0605 42.9906 33.1548C42.6362 33.5092 42.4984 34.0243 42.628 34.5067C42.7576 34.989 43.135 35.3664 43.6173 35.496C44.0997 35.6256 44.6165 35.4878 44.9692 35.1334C47.1529 32.9448 47.1529 29.4027 44.9692 27.2141L32.1001 14.345C29.9115 12.1597 26.3678 12.1597 24.1808 14.345L14.2813 24.2446C12.0976 26.4332 12.0976 29.9753 14.2813 32.1639L27.1504 45.0329C29.339 47.2166 32.8811 47.2166 35.0697 45.0329C35.424 44.6802 35.5619 44.1634 35.4322 43.6811C35.3026 43.1971 34.9253 42.8214 34.4429 42.6918Z" />
        <path d="M32.1001 32.164C30.4627 33.8063 30.4627 36.4625 32.1001 38.1047L46.9494 52.954C48.0092 54.0172 49.558 54.4339 51.0083 54.0451C52.4586 53.6562 53.5923 52.5242 53.9811 51.0722C54.3699 49.6203 53.9532 48.0732 52.8901 47.0133L38.0408 32.164C36.4002 30.5267 33.7423 30.5267 32.1017 32.164H32.1001ZM50.9098 50.9738C50.6473 51.2363 50.2913 51.384 49.9189 51.384C49.5465 51.384 49.1921 51.2363 48.928 50.9738L34.0787 36.1245C33.5373 35.5765 33.5389 34.6955 34.0836 34.1492C34.6283 33.6029 35.5109 33.6012 36.0589 34.1426L50.9082 48.9919C51.4545 49.5399 51.4545 50.4258 50.9082 50.9722L50.9098 50.9738Z" />
      </g>
      <defs>
        <clipPath id="clip0_736_2637">
          <rect width="84" height="84" fill="white" transform="translate(0 0.0634766)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
