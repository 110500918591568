import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useActiveWeb3React, useTransactionAdder, useSlotsContract, useSWRContract } from "hooks";
import { useTranslation } from "context";
import { getErrorMessage, isAddress, ZERO_ADDRESS } from "utils/web3";
import { ROUTES } from "navigation/routes";

export function useRegistration() {
  const [loading, setLoading] = useState(false);

  const { chainId, account } = useActiveWeb3React();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const transaction = useTransactionAdder();

  const slotsContract = useSlotsContract(chainId);
  // For some reason contract with signer can't use read methods in this hook
  // So new contract was created just for write methods
  const slotsContractWrite = useSlotsContract(chainId, true);

  const { data, isValidating, mutate } = useSWRContract([slotsContract, "userProfiles", [account ?? ZERO_ADDRESS]]);

  const currentRoute = location.pathname.split("/")[1];

  const isUserRegistered = useMemo(() => {
    if (data?.["isRegistered"]) {
      if (currentRoute === ROUTES.registration) {
        navigate(`/${ROUTES.main}`);
      }

      return true;
    }

    return false;
  }, [data?.["isRegistered"]]);

  const nickname: string = useMemo(() => {
    if (data?.["nickname"]) {
      return data["nickname"];
    }

    return "";
  }, [data?.["nickname"]]);

  const registerUser = async (nickname: string, referrer: string) => {
    const referralAddress = isAddress(referrer) ? referrer : ZERO_ADDRESS;

    try {
      if (referrer) {
        setLoading(true);
        const referrerInfo = await slotsContract?.userProfiles(referrer);

        if (referrerInfo && !referrerInfo["isRegistered"]) {
          toast.error(t("This referrer is not exists"));
          setLoading(false);

          return;
        }
      }

      setLoading(true);
      const nicknameUsed = await slotsContract?.nicknamesUsed(nickname);

      if (!nicknameUsed) {
        const response = await slotsContractWrite?.register(nickname, referralAddress);

        if (response) {
          transaction(response, {
            summary: t("Register successful"),
            type: "contractMethod",
            callback: () => {
              mutate();
              setLoading(false);
            },
          });
        }
      } else {
        toast.error(t("This nickname is used"));
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      console.error(`Registration failed: `, error);
      toast.error(t("Registration failed: %message%", { message: t(getErrorMessage(error)) }));
    }
  };

  return { registerUser, isUserRegistered, loading: loading || isValidating, nickname };
}
