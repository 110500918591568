export const breakpointMap = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
} as const;

export const breakpointsArray = ["320px", "375px", "425px", "768px", "1024px", "1440px"];

export const breakpoints = Object.assign(breakpointsArray, {
  mobileS: breakpointsArray[0],
  mobileM: breakpointsArray[1],
  mobileL: breakpointsArray[2],
  tablet: breakpointsArray[3],
  laptop: breakpointsArray[4],
  laptopL: breakpointsArray[5],
});

export const mediaQueries = {
  mobileS: `@media screen and (min-width: 0px)`,
  mobileM: `@media screen and (min-width: ${breakpointMap.mobileM}px)`,
  mobileL: `@media screen and (min-width: ${breakpointMap.mobileL}px)`,
  tablet: `@media screen and (min-width: ${breakpointMap.tablet}px)`,
  laptop: `@media screen and (min-width: ${breakpointMap.laptop}px)`,
  laptopL: `@media screen and (min-width: ${breakpointMap.laptopL}px)`,
} as const;

export const shadows = {
  card: "0px 0px 30px #0B0D13",
  list: "0px 2px 2px #0B0D13",
  switcher: "0px 1px 1px rgba(11, 13, 19, 0.5)",
  successProgressLine: "0px 0px 5px rgba(60, 246, 79, 0.8)",
  warningProgressLine: "0px 0px 5px rgba(255, 168, 0, 0.8)",
  errorProgressLine: "0px 0px 5px rgba(242, 13, 40, 0.8)",
} as const;

export const radii = {
  small: "4px",
  semiMedium: "8px",
  medium: "16px",
  large: "24px",
  extraLarge: "80px",
  circle: "50%",
} as const;

export const zIndices = {
  dropdown: 10,
  modal: 100,
  tooltip: 101,
} as const;

export const fontWeight = {
  bold: 900,
  semiBold: 800,
  extraMedium: 700,
  medium: 600,
  semiMedium: 500,
  regular: 400,
} as const;

export const fonts = {
  Orbitron: "Orbitron, sans-serif",
  NotoSans: "Noto Sans, sans-serif",
} as const;

export const hsl = {
  "50": 50,
  "100": 100,
  "200": 200,
  "300": 300,
  "400": 400,
  "500": 500,
  "800": 800,
  "900": 900,
} as const;

export default {
  siteWidth: 1440,
  breakpoints,
  mediaQueries,
  shadows,
  radii,
  zIndices,
  fonts,
  fontWeight,
  hsl,
};
