import { Zero } from "@ethersproject/constants";
import { toast } from "react-toastify";
import useSWR from "swr";
// Context
import { useTranslation } from "context";
// Hooks
import { useActiveWeb3React, useCurrentBlock, useWeeklyLotteryContractFromCustomRPC } from ".";
// Store
import { useAppDispatch } from "store/store";
import { checkedNominalTimestamp } from "store/nominals";
// Constants
import { ToastDescriptionLotteryDraw, toastOptionsSuccess } from "components";
import { formatBigNumber, formatBigNumberToFixed } from "utils/web3";

export const useGetWeeklyLotteryDraw = () => {
  const { account, chainId } = useActiveWeb3React();

  const weeklyContract = useWeeklyLotteryContractFromCustomRPC(chainId);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const blockNumber = useCurrentBlock();

  useSWR(
    blockNumber && chainId ? `${chainId}/getWeeklyLotteryDraw/${blockNumber}` : null,
    async () => {
      return getWeeklyLotteryDraw();
    },
    { revalidateOnFocus: false },
  );

  const getWeeklyLotteryDraw = async () => {
    const eventFilter = weeklyContract!.filters.LotteryDrawn();
    // blockNumber updates ones in two blocks, sometimes ones in a three blocks
    // So we add additional constant for extra check in case we lose blocks
    const events = await weeklyContract!.queryFilter(eventFilter, blockNumber - 2, blockNumber);

    events.forEach(({ args }) => {
      const lotteryWinners = args?.["winners"] ?? [];
      const betBN = args?.["bet"] ?? Zero;
      const bet = formatBigNumber(betBN);
      const prizes = args?.["prizes"] ?? [];

      dispatch(checkedNominalTimestamp({ nominal: bet === "0" ? "monthly" : bet, isChecked: true }));

      if (lotteryWinners.some(winner => winner === account)) {
        const playerWinnerPlace = lotteryWinners.indexOf(account ?? "");
        toast.success(
          <ToastDescriptionLotteryDraw
            description={t("You win %message% BNB in weekly lottery.", {
              message: formatBigNumberToFixed(prizes[playerWinnerPlace], 4),
            })}
          />,
          toastOptionsSuccess,
        );
      }
      return;
    });
  };
};
